import React, { useState } from 'react'

import Footer from '../components/Footer';
import NavComponent from '../components/NavComponent';
import {  Outlet } from 'react-router-dom';


function TeacherMainpage() {
  const [selectedPage, setSelectedPage] = useState('')
  const options = [
    {
      name: 'Home',
      link: '/teacher'
    },
    {
      name: 'profile',
      link: '/teacher/profile'
    },
    {
      name: 'books',
      link: '/teacher/books/listings'
    },
    {
      name: 'library',
      link: '/teacher/library'
    },
    {
      name: 'courses',
      link: '/teacher/courses'
    },
  ]
  
  
  return (
    <div className="min-h-screen flex flex-col">
      <header className="w-full z-40 fixed top-0 h-40">
        <NavComponent options={options} selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
      </header>

      <main className="flex-auto">  
        <Outlet />  
      </main>

      <footer className="w-full bg-black py-10 px-24">
        <Footer selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
      </footer>
    </div>
  )
}

export default TeacherMainpage