import React from 'react'
import { Link } from 'react-router-dom'

function ListItem({title, detail, route}) {
  return (
    <Link
      to = {route} 
      className='shadow-lg w-full transform transition-all rounded-xl bg-slate-50 hover:bg-yellow-100 duration-500 px-10 py-8 flex flex-col gap-4'>
        <h1 className='text-2xl'>{title}</h1>
        <p>{detail}</p>
    </Link>
  )
}

export default ListItem