import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import pfp from '../assets/Default_pfp.svg'
import Loader from '../components/Loader'
import getApiKey from '../apiKeys'

function Profile() {
  const endpoint = getApiKey()
  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')

    useEffect(() => {
        const fetchBooks = async () => {
          try {
            setLoading(true)
            const response = await axios.get(`${endpoint}/api/profile`, { headers: {"Authorization" : `Bearer ${token}`} }) //change
            console.log(response.data)
            setProfile(response.data)
            setLoading(false)
          } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
          }
        }

        fetchBooks()
    }, [])


  return loading ? <Loader /> :(
    <div className="relative flex flex-col justify-center min-h-screen">
        <div className="w-full my-20 mt-40 p-6 m-auto bg-slate-50  rounded-md shadow-xl lg:max-w-xl">
          <div className="flex justify-center">
              <img src={pfp} alt="" className="bg-white rounded-full mx-auto absolute top-24 w-32 h-32 shadow-md border-4 border-white" />
          </div>
                      
          <div className="mt-16">
              <h1 className="font-bold text-center text-3xl text-gray-900">{profile.first_name} {profile.middle_name} {profile.last_name}</h1>
              <p className="text-center text-sm text-gray-400 font-medium">{profile.role}</p>
              <div className="my-5 px-6 mt-16">
                  <p className="text-gray-200 block rounded-lg text-center font-medium leading-6 px-6 py-3 bg-gradient-to-r from-red-500 to-yellow-500 ">Personal information</p>
              </div>

              <div className="flex flex-col gap-20 md:mx-16 my-12">
                  <div>
                      <div className="flex flex-col gap-3 px-4">
                          {profile.email && <div  className="grid grid-cols-2 gap-20 py-2 px-2 bg-slate-100">
                              <p>Email:</p>
                              <p>{profile.email}</p>
                          </div>}
                          {profile.program && <div  className="grid grid-cols-2 gap-20 py-2 px-2">
                              <p>Program:</p>
                              <p>{profile.program}</p>
                          </div>}
                          {profile.department && <div  className="grid grid-cols-2 gap-20 py-2 px-2 bg-slate-100">
                              <p>Department:</p>
                              <p>{profile.department}</p>
                          </div>}
                          {profile.year && <div  className="grid grid-cols-2 gap-20 py-2 px-2">
                              <p>Year:</p>
                              <p>{profile.year}</p>
                          </div>}
                          {profile.section !== null && <div  className="grid grid-cols-2 gap-20 py-2 px-2 bg-slate-100">
                              <p className='capitalize'>section:</p>
                              <p>{profile.section}</p>
                          </div>}
                          {profile.phone_number && <div  className="grid grid-cols-2 gap-20 py-2 px-2">
                              <p className='capitalize'>phone number:</p>
                              <p>{profile.phone_number}</p>
                          </div>}
                          {profile.institution && <div  className="grid grid-cols-2 gap-20 py-2 px-2 bg-slate-100">
                              <p className='capitalize'>institution:</p>
                              <p>{profile.institution}</p>
                          </div>}
                          {profile.specialization && <div  className="grid grid-cols-2 gap-20 py-2 px-2">
                              <p className='capitalize'>specialization:</p>
                              <p>{profile.specialization}</p>
                          </div>}
                          {profile.academic_rank && <div  className="grid grid-cols-2 gap-20 py-2 px-2 bg-slate-100">
                              <p className='capitalize'>academic rank:</p>
                              <p>{profile.academic_rank}</p>
                          </div>}
                      </div>
                  </div>
              </div>
          </div>

        </div>
      </div>
  )
}

export default Profile