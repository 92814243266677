

import React, { useEffect, useState } from 'react'
import Loader from '../components/Loader'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import getApiKey from '../apiKeys'


function ViewGrade() {
  const endpoint = getApiKey()
  const [loading, setLoading] = useState(false)
  const [courses, setCourses] = useState({})
  const [grades, setGrades] = useState([])
  const token = localStorage.getItem('token')
  const decoded = jwt_decode(token)
  

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true)
      const response = await axios.get(`${endpoint}/api/courses`, { headers: {"Authorization" : `Bearer ${token}`} })
      // console.log(response.data)
      response.data.forEach(element => {
        setCourses((prev) => ({
          ...prev,
          [element.id]: {
            course_name: element.course_name,
            ects: element.ects,
            credit_hours: element.credit_hours
          } 
        }))
        setLoading(false)
      });
  }

  const fetchGrades = async () => {
    setLoading(true)
    const res = await axios.get(`${endpoint}/api/${decoded.user_id}/result`, { headers: {"Authorization" : `Bearer ${token}`} })
    setGrades(res.data)
    setLoading(false)
  }
  

    
    
    fetchCourses()
    fetchGrades()
}, [])

    const getTotal = () => {
      let total = 0
      grades.forEach((item) => {
        total += (courses[item.course_id].credit_hours * item.grade_point)
      })
      return total
    }

    const getGPA = () => {
      const total = getTotal()
      let totalEcts = 0
      grades.forEach((item) => {
        totalEcts += courses[item.course_id].credit_hours
      })
      const gpa = total / totalEcts
      return gpa.toFixed(2)
    }

//   const getTotalCredit = () => {
//     let total = 0
//     courses.forEach((item) => total = total + Number(item.credit_hours))
//     return total.toString()
// }

// const getTotalEcts = () => {
//     let total = 0
//     courses.forEach((item) => total = total + Number(item.ects))
//     return total.toString()
// }

  return loading ? <Loader /> : (
    <div className="flex flex-col justify-center my-10 mt-40">
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4">
        <div className='grid grid-cols-12 gap-2 bg-slate-100 justify-center items-center block text-sm text-gray-800 py-2'>
            <div></div>
            <p className='col-span-3'>Courses</p>
            <p className='col-span-2'>ECTS</p>
            <p className='col-span-2'>Credit</p>
            <p className='col-span-2'>Average</p>
            <p className='col-span-2'>Grade</p>
        </div>
        <div>
            {grades.map((item, i) => <div 
            key={i}
            className='grid grid-cols-12 gap-2 justify-center items-center text-sm text-gray-800 py-2'
            >
                <p
                className='w-4 h-4'
                ></p>
                <p className='col-span-3'>{courses[item.course_id].course_name}</p>
                <p className='col-span-2'>{courses[item.course_id].ects}</p>
                <p className='col-span-2'>{courses[item.course_id].credit_hours}</p>
                <p className='col-span-2'>{item.total}</p>
                <p className='col-span-2'>{item.grade}</p>
            </div>)}
        </div>
        {/* <div className='grid grid-cols-12 gap-2 bg-slate-100 justify-center items-center block text-sm text-gray-800 py-2'>
            <p></p>
            <p className='col-span-3'>Total:</p>
            <p className='col-span-2'></p>
            <p className='col-span-2'>{getTotalEcts()}</p>
            <p className='col-span-2'>{getTotalCredit()}</p>
            <p className='col-span-2'>3.75</p>
        </div> */}
      </div>

        <div className="mt-20 w-full p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4">
        <div className='grid grid-cols-12 gap-2 bg-slate-100 justify-center items-center block text-sm text-gray-800 py-2'>
            <div></div>
            <p className='col-span-3'></p>
            <p className='col-span-2'>SGP</p>
            <p className='col-span-2'>CGP</p>
            <p className='col-span-2'>SGPA</p>
            <p className='col-span-2'>CGPA</p>
        </div>
        <div 
            className='grid grid-cols-12 gap-2 justify-center items-center text-sm text-gray-800 py-2'
            >
                <p
                className='w-4 h-4'
                ></p>
                <p className='col-span-3'></p>
                <p className='col-span-2'>{getTotal()}</p>
                <p className='col-span-2'>{getTotal()}</p>
                <p className='col-span-2'>{getGPA()}</p>
                <p className='col-span-2'>{getGPA()}</p>
        </div>
        {/* <div className='grid grid-cols-12 gap-2 bg-slate-100 justify-center items-center block text-sm text-gray-800 py-2'>
            <p></p>
            <p className='col-span-3'>Total:</p>
            <p className='col-span-2'></p>
            <p className='col-span-2'>{getTotalEcts()}</p>
            <p className='col-span-2'>{getTotalCredit()}</p>
            <p className='col-span-2'>3.75</p>
        </div> */}
        </div>
    </div>
  )
}

export default ViewGrade
