import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AsyncSelect from "react-select/async";
import getApiKey from '../apiKeys';

function CreateCourseRegistration() {
  const endpoint = getApiKey()
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const departments = {
    TVET: [
      "Human Resource Management",
      "Accounting and Finance",
      "Marketing Sales and Management",
    ],
    Degree: ["Management", "Accounting and Finance"],
    Masters: ["Masters of Business Administration", "Project Management"],
  };

  const [getData, setgetData] = useState({
    program: "TVET",
    department: "Human Resource Management",
  });
  const [formData, setFormData] = useState({
    year: "1",
    semester: "1",
    course_ids: [],
    end_date: "",
    program: "TVET",
    department: "Human Resource Management",
  });
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  // useEffect(() => {
  //     const fetchCourses = async () => {
  //         const response = await axios.get(`${endpoint}/api/courses`, { headers: {"Authorization" : `Bearer ${token}`} })
  //         const ops = response.data.map((item) => {
  //             return {
  //                 value: item.id,
  //                 label: item.course_name
  //                 }
  //         })
  //         setOptions(ops)
  //         setLoading(false)
  //     }

  //     setLoading(true)
  //     fetchCourses()
  // }, [])

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleInitialChange = (e) => {
    setgetData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    if (e.target.id === "program") {
      setgetData((prevState) => ({
        ...prevState,
        department: departments[e.target.value][0],
      }));
      setFormData((prevState) => ({
        ...prevState,
        department: departments[e.target.value][0],
      }));
    }
  };

  const handleSelect = (options) => {
    const chosen = options.map((item) => item.value);
    setFormData((prevState) => ({
      ...prevState,
      course_ids: chosen,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${endpoint}/api/course-for-registration`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      toast.success("Course Registration created succefully");
      navigate("/admin");
    } catch (error) {
      console.group(error);
      toast.error(error.response.data.message);
    }

    setLoading(false);
  };

  const fetchCourses = async () => {
    setLoading(true);
    setFormData((prev) => ({
      ...prev,
      program: getData.program,
      department: getData.department,
    }));
    const response = await axios.get(
      `${endpoint}/api/${getData.program}/${getData.department}/courses`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const ops = response.data.map((item) => {
      return {
        value: item.id,
        label: item.course_name,
      };
    });
    setOptions(ops);
    setLoading(false);
  };

  const searchCourse = async (inputValue) => {
    console.log(options);
    return options.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(searchCourse(inputValue));
    });

  return loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col justify-center my-10 mt-40">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
          Create a Course Registration
        </h1>
        <div className="mt-6">
          <div className="mb-2">
            <label
              htmlFor="program"
              className="block text-sm font-semibold text-gray-800"
            >
              Program
            </label>
            <select
              id="program"
              onChange={handleInitialChange}
              value={formData.program}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              <option value="TVET">TVET</option>
              <option value="Degree">Bachelors</option>
              <option value="Masters">Masters</option>
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlFor="department"
              className="block text-sm font-semibold text-gray-800"
            >
              Department
            </label>
            <select
              id="department"
              onChange={handleInitialChange}
              value={formData.department}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              {departments[formData.program].map((dep) => {
                return (
                  <option key={dep} value={dep}>
                    {dep}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mt-6">
            <button
              onClick={fetchCourses}
              className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
            >
              Get Courses
            </button>
          </div>
        </div>
        {options.length > 0 && (
          <form className="mt-6">
            <div className="mb-2">
              <label
                htmlFor="year"
                className="block text-sm font-semibold text-gray-800"
              >
                Year
              </label>
              <select
                id="year"
                onChange={handleChange}
                value={formData.year}
                className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
              >
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
            <div className="mb-2">
              <label
                htmlFor="semester"
                className="block text-sm font-semibold text-gray-800"
              >
                Semester
              </label>
              <select
                id="semester"
                onChange={handleChange}
                value={formData.semester}
                className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
            <div className="mb-2">
              <label
                htmlFor="prerequisites"
                className="block text-sm font-semibold text-gray-800"
              >
                Please select the Courses the chosen students should register
                to.
              </label>
              <div className="mt-2">
                <AsyncSelect
                  isMulti
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  onChange={handleSelect}
                />
              </div>
            </div>
            <div className="mb-2">
              <label
                htmlFor="end_date"
                className="block text-sm font-semibold text-gray-800"
              >
                End Date
              </label>
              <input
                id="end_date"
                onChange={handleChange}
                value={formData.end_date}
                type="date"
                className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            <div className="mt-16">
              <button
                onClick={handleSubmit}
                className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
              >
                Create
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default CreateCourseRegistration;
