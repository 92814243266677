import React, { useEffect, useState } from 'react'
import Loader from '../components/Loader'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import getApiKey from '../apiKeys'

function StudentMarkList() {
  const endpoint = getApiKey()
  const navigate = useNavigate()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState([])
  const [course, setCourse] = useState({})
  const token = localStorage.getItem('token')
  const [formData, setFormData] = useState([])

  const handleSubmit = async(e) => {
    e.preventDefault()
    setLoading(true)

    const request = formData.map((student) => {
      return {
        user_id: student.user_id,
        course_id: params.course_id,
        detail_results: [
          {
            value: student.assesment,
            out_of: 50
          },
          {
            value: student.final,
            out_of: 50
          },
        ]
      }
    })
    console.log(request)

    try {
        const response = await axios.post(`${endpoint}/api/results`, {datas: request}, { headers: {"Authorization" : `Bearer ${token}`} })
        console.log(response.data)
        toast.success("uploaded succefully")
        navigate('/teacher/courses')
        
    } catch (error) {
        console.log(error)
        toast.error(error.response.data.message)
    }
    
    setLoading(false)
  }

  const hanldeChange = (e, item) => {
    let updated = formData
    for (let i = 0; i < updated.length; i++) {
      const student = updated[i];
      if (student.user_id === item.id){
        updated[i] ={
          ...updated[i],
          [e.target.id]: Number(e.target.value),
        }
        setFormData(formData)
        console.log(formData)
        break
      }
    }
  }

  const getTotal = (item) => {
    const curr = formData.filter((student) => item.id === student.user_id)
    return curr[0].assesment + curr[0].final
  }




  useEffect(() => {
    const fetchStudents = async () => {
        const response = await axios.get(`${endpoint}/api/course/${params.course_id}/students/${params.section}`, { headers: {"Authorization" : `Bearer ${token}`} })
        setStudents(response.data)

        const formGroup = response.data.map((student) => {
                              return {
                                user_id: student.id,
                                assesment: 0,
                                final: 0,
                                total: 0
                              }
                            })
        setFormData(formGroup)
        setLoading(false)
    }

    const fetchCourse = async () => {
        const response = await axios.get(`${endpoint}/api/courses/${params.course_id}`, { headers: {"Authorization" : `Bearer ${token}`} })
        console.log(response.data)
        setCourse(response.data)
        setLoading(false)
    }
    
    setLoading(true)
    fetchStudents()
    fetchCourse()
    
}, [])
  
  return loading ? <Loader /> : (
    <div className="flex flex-col justify-center my-10 mt-40">
        <form className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4">
          <p className="text-3xl font-inter mb-10 font-bold">{course.course_name}</p>
          <div className='grid grid-cols-12 gap-2 bg-slate-100 justify-center items-center block text-sm text-gray-800 py-2'>
              <div></div>
              <p className='col-span-2'>ID</p>
              <p className='col-span-5'>Name</p>
              <p className='col-span-2'>Assesment(50%)</p>
              <p className='col-span-2'>final(50%)</p>
              {/* <p className='col-span-2'>Total(100%)</p> */}
          </div>
          <div>
              {students.map((item, i) => <div 
              key={i}
              className='grid grid-cols-12 gap-2 justify-center items-center block text-sm text-gray-800 py-2'
              >
                  <p
                  className='w-4 h-4'
                  ></p>
                  <p className='col-span-2'>{item.student_id}</p>
                  <p className='col-span-5'>{item.first_name} {item.middle_name} {item.last_name}</p>
                  <input 
                    type='number' 
                    id='assesment'
                    className='border border-slate-300 rounded-lg w-16 h-10 col-span-2 p-2 focus:outline-yellow-500'
                    onChange={(e) => hanldeChange(e, item)}
                  />
                  <input
                    id='final' 
                    type='number' 
                    className='border border-slate-300 rounded-lg w-16 h-10 col-span-2 p-2 focus:outline-yellow-500'
                    onChange={(e) => hanldeChange(e, item)} 
                  />
                  {/* <p
                    className='border border-slate-300 rounded-lg w-16 h-10 col-span-2 p-2 focus:outline-yellow-500'
                  >
                    {getTotal(item)}
                  </p>  */}
                  
              </div>)}
          </div>
          <div className="mt-12 flex justify-end">
            <button 
                className="px-6 py-1 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
                onClick={handleSubmit}
              >
                Submit
            </button>
          </div>
      </form>
    </div>
  )
}

export default StudentMarkList