import React from 'react'

function Loader() {
    let circleCommonClasses = 'h-2.5 w-2.5 bg-gradient-to-r from-red-500 to-yellow-500  rounded-full';

    return (
        <div className='flex justify-center items-center min-h-screen min-w-screen'>
            <div className='flex'>
                <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                <div
                    className={`${circleCommonClasses} mr-1 animate-bounce200`}
                ></div>
                <div className={`${circleCommonClasses} animate-bounce400`}></div>
            </div>
        </div>
    );
}

export default Loader