import React, { useEffect, useMemo, useState } from 'react'
import pfp from '../assets/Default_pfp.svg'
import plus from '../assets/plus.svg'
import review from '../assets/review.svg'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loader from '../components/Loader'
import FilterComponent from '../components/FilterComponent'
import DataTable from 'react-data-table-component'
import getApiKey from '../apiKeys';

function TeachersList() {
    const navigate = useNavigate()
    const [teachers, setTeachers] = useState([])
    const token = localStorage.getItem('token')
    const [loading, setLoading] = useState(false)
    const endpoint = getApiKey()
  
    const columns = [
        {
            name: "Full Name",
            selector: (teacher) => teacher.first_name + ' ' + teacher.middle_name + ' ' + teacher.last_name,
            sortable: true,
        },
        {
            name: "Email",
            selector: (teacher) => teacher.email,
            sortable: true,
        },
        {
            name: "Phone Number",
            selector: (teacher) => teacher.phone_number, //change starts here
            sortable: true,
        },
        {
            name: "Institution",
            selector: (teacher) => teacher.institution,
            sortable: true,
        },
        {
            name: "Specialization",
            selector: (teacher) => teacher.specialization,
            sortable: true,
        },
        {
            name: "Academic Rank",
            selector: (teacher) => teacher.academic_rank,
            sortable: true,
        },
    ]

    const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);
    

    

    useEffect(() => {
        const fetchTeachers = async () => {
            const response = await axios.get(`${endpoint}/api/teachers`, { headers: {"Authorization" : `Bearer ${token}`} }) //change endpoint
            setTeachers(response.data)
            console.log("Teachers", response.data)
            setLoading(false)
        }

        
        setLoading(true)
        fetchTeachers()
    }, [])
    
    return loading ? <Loader /> : (
    <div className="relative flex flex-col justify-center my-10 mt-40">
        <div 
            className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4"
        >
            <DataTable 
                title="Teachers"
                columns={columns}
                data={teachers.filter((item) => {
                    if (filterText === "") {
                      return item;
                    } else if (
                      item.first_name.toLowerCase().includes(filterText.toLowerCase()) ||
                      item.middle_name.toLowerCase().includes(filterText.toLowerCase()) ||
                      item.last_name.toLowerCase().includes(filterText.toLowerCase())
                    ) {
                      return item;
                    }
                  })}
                progressPending={loading}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
            />


            {/* <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {teachers.map((teacher) => {
                    return (<li key={teacher.id} className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-shrink-0">
                                <img className="w-8 h-8 rounded-full" src={pfp} alt="pfp" />
                            </div>
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate dark:text-white capitalize">
                                    {teacher.first_name + ' ' + teacher.middle_name + ' ' + teacher.last_name}
                                </p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {teacher.email}
                                </p>
                            </div>
                            
                        </div>
                    </li>)
                })}
            </ul> */}
        </div>
        <div 
            className='absolute bottom-20  right-5 w-16 h-16 rounded-full shadow-xl bg-yellow-500 text-black hover:scale-110 duration-500 hover:cursor-pointer flex justify-center items-center'
            onClick={() => navigate('/admin/teachers/applied')}
            title="View teacher applications"
        >
            <img src={review} alt='review' className='w-8 h-8'/>
        </div>
        <div 
            className='absolute bottom-0  right-5 w-16 h-16 rounded-full shadow-xl bg-yellow-500 text-black hover:scale-110 duration-500 hover:cursor-pointer flex justify-center items-center'
            onClick={() => navigate('/admin/signup/teacher')}
            title="Add teacher"
        >
            <img src={plus} alt='add' className='w-8 h-8'/>
        </div>
    </div>
  )
}

export default TeachersList