import React, { useEffect, useState } from 'react'
import Loader from '../components/Loader'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import AsyncSelect from 'react-select/async';
import getApiKey from '../apiKeys'

function AddCourse() {
    const endpoint = getApiKey()
  const navigate = useNavigate()
    const [options, setOptions] = useState([])
    const [formData, setFormData] = useState({
        course_name: "",
        course_code: "",
        ects: 0,
        credit_hours: "2",
        department: "Human Resource Management",
        year: "1",
        semester: "1",
        program: "TVET",
        description: '',
        prerequisites: [],
    })
    const departments = {
        TVET: [
          "Human Resource Management",
          "Accounting and Finance",
          "Marketing Sales and Management",
        ],
        Degree: ["Management", "Accounting and Finance"],
        Masters: ["Masters of Business Administration", "Project Management"],
      };
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('token')

    // const style = {
    //     control: base => ({
    //       ...base,
    //       border: 0,
    //       // This line disable the blue border
    //       boxShadow: 'none'
    //     })
    //   };

    useEffect(() => {
        const fetchCourses = async () => {
            const response = await axios.get(`${endpoint}/api/courses`, { headers: {"Authorization" : `Bearer ${token}`} })
            const ops = response.data.map((item) => {
                return {
                    value: item.id, 
                    label: item.course_name
                    }
            })
            setOptions(ops)
            setLoading(false)
        }

        
        setLoading(true)
        fetchCourses()
    }, [])

    const handleChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value,
        }));
        if (e.target.id === "program") {
          setFormData((prevState) => ({
            ...prevState,
            department: departments[e.target.value][0],
          }));
        }
      };

    const handleSelect = (options) => {
        const chosen = options.map((item) => item.value)
        setFormData((prevState) => ({
            ...prevState,
            prerequisites: chosen
        }))
    }

    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        console.log(formData)

        try {
            const response = await axios.post(`${endpoint}/api/courses`, formData, { headers: {"Authorization" : `Bearer ${token}`} })
            console.log(response.data)
            toast.success("Course Added succefully")
            // navigate('/admin')
            
        } catch (error) {
            console.group(error)
            toast.error(error.message)
        }
        
        setFormData({
            course_name: "",
            course_code: "",
            ects: 0,
            credit_hours: "2",
            department: "Human Resource Management",
            year: "1",
            semester: "1",
            program: "TVET",
            description: '',
            prerequisites: [],
        })
        setLoading(false)
    }

    const searchCourse = async (inputValue) => {
        console.log(options)
        return options.filter((item) => item.label.toLowerCase().includes(inputValue.toLowerCase()))
    }

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            resolve(searchCourse(inputValue));
        });

  return loading ? <Loader /> : (
    <div className="flex flex-col justify-center my-10 mt-40">
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
            <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
                Add a Course
            </h1>
            <form className="mt-6">
                <div className="mb-2">
                    <label
                        htmlFor="course_name"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Course Name
                    </label>
                    <input
                        id='course_name'
                        onChange={handleChange}
                        value={formData.course_name}
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="course_code"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Course Code
                    </label>
                    <input
                        id='course_code'
                        onChange={handleChange}
                        value={formData.course_code}
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                {/* <div className="mb-2">
                    <label
                        htmlFor="ects"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        ECTS
                    </label>
                    <input
                        id='ects'
                        onChange={handleChange}
                        value={formData.ects}
                        type="number"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div> */}
                <div className="mb-2">
                    <label
                        htmlFor="credit_hours"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Credit Hour
                    </label>
                    <select
                        id='credit_hours'
                        onChange={handleChange}
                        value={formData.credit_hours}
                        className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="program"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Program
                    </label>
                    <select
                        id='program'
                        onChange={handleChange}
                        value={formData.program}
                        className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                        <option value="TVET">TVET</option>
                        <option value="Degree">Bachelors</option>
                        <option value="Masters">Masters</option>
                    </select>
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="department"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Department
                    </label>
                    <select
                  id="department"
                  onChange={handleChange}
                  value={formData.department}
                  className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                >
                  {departments[formData.program].map((dep) => {
                    return (
                      <option key={dep} value={dep}>
                        {dep}
                      </option>
                    );
                  })}
                </select>
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="year"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Year
                    </label>
                    <select
                        id='year'
                        onChange={handleChange}
                        value={formData.year}
                        className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="semester"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Semester
                    </label>
                    <select
                        id='semester'
                        onChange={handleChange}
                        value={formData.semester}
                        className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="description"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Descritpion
                    </label>
                    <textarea
                        onChange={handleChange}
                        id='description'
                        value={formData.description}
                        className="block w-full h-28 px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    > </textarea>
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="prerequisites"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Please select the pre-requiste courses for this course.
                    </label>
                    <div className='mt-2'>
                        <AsyncSelect
                            // styles={style}
                            isMulti
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseOptions}
                            onChange={handleSelect}
                        />
                    </div>
                </div>
                <div className="mt-16">
                    <button onClick={handleSubmit} className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none">
                        Add
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default AddCourse