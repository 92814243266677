import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AsyncSelect from "react-select/async";
import getApiKey from '../apiKeys';

function AssignTeacherToCourse() {
  const endpoint = getApiKey()
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [formData, setFormData] = useState({
    department: "Human Resource Management",
    year: "1",
    section: null,
    program: "TVET",
    course_id: null,
    teacher_id: null,
  });
  const departments = {
    TVET: [
      "Human Resource Management",
      "Accounting and Finance",
      "Marketing Sales and Management",
    ],
    Degree: ["Management", "Accounting and Finance"],
    Masters: ["Masters of Business Administration", "Project Management"],
  };
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await axios.get(`${endpoint}/api/courses`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const ops = response.data.map((item) => {
        return {
          value: item.id,
          label: item.course_name,
        };
      });
      setOptions(ops);
    };

    const fetchTeachers = async () => {
      const response = await axios.get(`${endpoint}/api/teachers`, {
        headers: { Authorization: `Bearer ${token}` },
      }); //change endpoint
      const ops = response.data.map((item) => {
        return {
          value: item.id,
          label: item.first_name + " " + item.last_name,
        };
      });
      setTeachers(ops);
      setLoading(false);
    };

    setLoading(true);
    fetchCourses();
    fetchTeachers();
  }, []);

  const handleSelect = (option) => {
    setFormData((prevState) => ({
      ...prevState,
      course_id: option.value,
    }));
  };

  const handleSelectTeacher = (teacher) => {
    setFormData((prevState) => ({
      ...prevState,
      teacher_id: teacher.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    try {
      const response = await axios.post(
        `${endpoint}/api/assign-courses`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      toast.success("Course Added succefully");
      navigate("/admin/assign");
    } catch (error) {
      console.group(error);
      toast.error(error.response.data.message);
    }

    setFormData((prevState) => ({
      ...prevState,
      course_id: null,
      teacher_id: null,
    }));
    setLoading(false);
  };

  const searchCourse = async (inputValue) => {
    return options.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const searchTeacher = async (inputValue) => {
    return teachers.filter((item) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(searchCourse(inputValue));
    });

  const promiseTeachers = (inputValue) =>
    new Promise((resolve) => {
      resolve(searchTeacher(inputValue));
    });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    if (e.target.id === "program") {
      setFormData((prevState) => ({
        ...prevState,
        department: departments[e.target.value][0],
      }));
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col justify-center my-10 mt-40">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
          Assign a Course to Teacher
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label
              htmlFor="program"
              className="block text-sm font-semibold text-gray-800"
            >
              Program
            </label>
            <select
              id="program"
              onChange={handleChange}
              value={formData.program}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              <option value="TVET">TVET</option>
              <option value="Degree">Bachelors</option>
              <option value="Masters">Masters</option>
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlFor="department"
              className="block text-sm font-semibold text-gray-800"
            >
              Department
            </label>
            <select
              id="department"
              onChange={handleChange}
              value={formData.department}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              {departments[formData.program].map((dep) => {
                return (
                  <option key={dep} value={dep}>
                    {dep}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlFor="year"
              className="block text-sm font-semibold text-gray-800"
            >
              Year
            </label>
            <select
              id="year"
              onChange={handleChange}
              value={formData.year}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlFor="section"
              className="block text-sm font-semibold text-gray-800"
            >
              section
            </label>
            <input
              onChange={handleChange}
              value={formData.section}
              id="section"
              type="number"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="prerequisites"
              className="block text-sm font-semibold text-gray-800"
            >
              select the course
            </label>
            <div className="mt-2">
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                onChange={handleSelect}
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="prerequisites"
              className="block text-sm font-semibold text-gray-800"
            >
              choose the teacher
            </label>
            <div className="mt-2">
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={promiseTeachers}
                onChange={handleSelectTeacher}
              />
            </div>
          </div>
          <div className="mt-16">
            <button
              onClick={handleSubmit}
              className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
            >
              Assign
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AssignTeacherToCourse;
