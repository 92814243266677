import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'
import axios from 'axios'
import getApiKey from '../apiKeys'

function CreateAnnouncement() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        file: null,
        internal: false,
    })
    const token = localStorage.getItem('token')
    const endpoint = getApiKey()
  


    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const handleBool = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            internal: !prevState['internal']
        }))
    }

    const handleFileUpload = (e) => {
        
        setFormData((prevState) => ({
            ...prevState,
            file: e.target.files[0]
        }))

        // let fileReader = new FileReader();
        // fileReader.readAsDataURL(e.target.files[0]);

        // fileReader.onload = (event) => {
        //     setFormData((prevState) => ({
        //         ...prevState,
        //         file: event.target.result
        //     }))
        // }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        
        const data = new FormData()
        data.append('title', formData.title);
        data.append('description', formData.description);
        data.append('file', formData.file);

        try {
            if (!formData.internal){
                const response = await axios.post(`${endpoint}/api/announcements`, data, { headers: {"Authorization" : `Bearer ${token}`} })
                console.log(response.data)
            }
            const response = await axios.post(`${endpoint}/api/internal-announcemets`, data, { headers: {"Authorization" : `Bearer ${token}`} })
            console.log("internal", response.data)
            toast.success("published succefully")
            navigate('/admin')
            
        } catch (error) {
            console.group(error)
            toast.error(error.message)
        }
        
        setLoading(false)
    }

  return loading ? <Loader /> : (
    <div className="flex flex-col justify-center my-10 mt-40">
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
            <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
                Create an Announcement
            </h1>
            <form className="mt-6">
                <div className="mb-2">
                    <input
                        id='internal'
                        onChange={handleBool}
                        value={formData.internal}
                        type="checkbox"
                        // className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <label
                        htmlFor="internal"
                        className="pl-2 text-sm font-semibold text-slate-500"
                    >
                        Check the box of you want it to be an Internal Announcement Only
                    </label>
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="title"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Title
                    </label>
                    <input
                        id='title'
                        onChange={handleChange}
                        value={formData.title}
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="description"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Descritpion
                    </label>
                    <textarea
                        onChange={handleChange}
                        id='description'
                        value={formData.description}
                        className="block w-full px-4 py-2 mt-2 h-28 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    > </textarea>
                </div>
                <div className="mt-8 mb-2">
                    <label
                        htmlFor="courseFile"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Upload the Official Announcement file here:
                    </label>
                    <input
                        type="file"
                        id='courseFile'
                        onChange={handleFileUpload}
                        className="block w-full rounded-md border mt-2 file:bg-gradient-to-r file:from-red-500 file:to-yellow-500 file:text-white file:h-full file:px-4 file:py-1 file:border-none focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                
                <div className="mt-6">
                    <button 
                    onClick={handleSubmit}
                    className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
                    >
                        Publish
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default CreateAnnouncement