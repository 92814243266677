import React, { useEffect, useState } from "react";
import { AcademicCapIcon } from "@heroicons/react/solid";
import axios from "axios";
import getApiKey from "../apiKeys";
import Loader from "../components/Loader";

// const announcements = [
//   {
//     id: 1,
//     title: 'New Feature: Dark Mode',
//     description: 'We are excited to announce the release of our new dark mode feature!',
//     date: 'May 20, 2023',
//   },
//   {
//     id: 2,
//     title: 'System Maintenance',
//     description: 'Our system will be undergoing maintenance on May 25, 2023 from 10:00 PM to 2:00 AM EST.',
//     date: 'May 23, 2023',
//   },
//   {
//     id: 3,
//     title: 'Upcoming Webinar',
//     description: 'Join us for our upcoming webinar on May 30, 2023 at 2:00 PM EST.',
//     date: 'May 22, 2023',
//   },
// ];

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const endpoint = getApiKey();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const response = await axios.get(
        `${endpoint}/api/internal-announcemets`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setAnnouncements(response.data);
    };

    setLoading(true);
    fetchAnnouncements();
    setLoading(false);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col gap-3 items-start justif p-6 md:w-3/4 mx-auto sm:py-12 my-10 mt-40 md:mt-20">
      <p className="text-xl md:text-3xl font-inter my-6 ml-2 font-bold">
        Announcements
      </p>
      <div className="shadow-xl rounded-xl w-full">
        <ul className="divide-y divide-gray-200">
          {announcements.map((announcement) => (
            <li key={announcement.id}>
              <a
                href={`${endpoint}${announcement.file}`}
                className="block hover:bg-yellow-50"
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div className="text-sm font-medium text-yellow-500 truncate">
                      {announcement.title}
                    </div>
                    <div className="ml-2 flex-shrink-0 flex">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-500 text-white">
                        New
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <div className="mr-6 flex items-center text-sm text-gray-500">
                        <AcademicCapIcon className="h-5 w-5 mr-1 text-gray-400" />
                        <span>{announcement.date}</span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    {announcement.description}
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Announcements;
