import React, { useEffect, useMemo, useState } from "react";
import plus from "../assets/plus.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import DataTable from "react-data-table-component";
import FilterComponent from "../components/FilterComponent";
import getApiKey from "../apiKeys";

function StudentsList() {
  const endpoint = getApiKey()
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const departments = {
    TVET: [
      "Human Resource Management",
      "Accounting and Finance",
      "Marketing Sales and Management",
    ],
    Degree: ["Management", "Accounting and Finance"],
    Masters: ["Masters of Business Administration", "Project Management"],
  };

  const [formData, setFormData] = useState({
    program: "TVET",
    department: "Human Resource Management",
    year: "1",
  });

  const columns = [
    {
      name: "ID",
      selector: (student) => student.student_id,
      sortable: true,
    },
    {
      name: "Full Name",
      selector: (student) =>
        student.first_name +
        " " +
        student.middle_name +
        " " +
        student.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (student) => student.email,
      sortable: true,
    },
    {
      name: "Program",
      selector: (student) => formData.program,
      sortable: true,
    },
    {
      name: "Department",
      selector: (student) => formData.department,
      sortable: true,
    },
    {
      name: "Year",
      selector: (student) => formData.year,
      sortable: true,
    },
    {
      name: "Section",
      selector: (student) => student.section,
      sortable: true,
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const fetchStudents = async () => {
    setLoading(true)
    const response = await axios.get(`${endpoint}/api/${formData.program}/${formData.department}/${formData.year}/student`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setStudents(response.data);
    console.log(response.data);
    setLoading(false);
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    if (e.target.id === "program") {
      setFormData((prevState) => ({
        ...prevState,
        department: departments[e.target.value][0],
      }));
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className=" relative flex flex-col justify-center my-10 mt-24">
        
          <div className="w-full md:w-2/3 p-6 m-auto bg-slate-50 rounded-md shadow-xl flex flex-col justify-center">
            <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
              Students List
            </h1>
            <div className="mt-6 mx-auto flex flex-col md:flex-row gap-8 justify-center md:items-end">
              <div className="">
                <label
                  htmlFor="program"
                  className="block text-sm font-semibold text-gray-800"
                >
                  Program
                </label>
                <select
                  id="program"
                  onChange={handleChange}
                  value={formData.program}
                  className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                >
                  <option value="TVET">TVET</option>
                  <option value="Degree">Bachelors</option>
                  <option value="Masters">Masters</option>
                </select>
              </div>
              <div className="">
                <label
                  htmlFor="department"
                  className="block text-sm font-semibold text-gray-800"
                >
                  Department
                </label>
                <select
                  id="department"
                  onChange={handleChange}
                  value={formData.department}
                  className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                >
                  {departments[formData.program].map((dep) => {
                    return (
                      <option key={dep} value={dep}>
                        {dep}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="">
                <label
                  htmlFor="year"
                  className="block text-sm font-semibold text-gray-800"
                >
                  Year
                </label>
                <select
                  id="year"
                  onChange={handleChange}
                  value={formData.year}
                  className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
              <div className="">
                <button
                  onClick={fetchStudents}
                  className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
                >
                  Get Students
                </button>
              </div>
            </div>
        </div>

      <div className="w-full mt-5 p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4">

        {students && (
          <DataTable
            id="table"
            title="Students"
            columns={columns}
            data={students.filter((item) => {
              if (filterText === "") {
                return item;
              } else if (
                item.first_name
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                item.middle_name
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                item.last_name.toLowerCase().includes(filterText.toLowerCase())
              ) {
                return item;
              }
            })}
            progressPending={loading}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        )}

        {/* <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {students.map((student) => {
                    return (<li key={student.id} className="py-3 sm:py-4">
                        <div className="flex items-center space-x-4">
                            <div className="flex-shrink-0">
                                <img className="w-8 h-8 rounded-full" src={pfp} alt="pfp" />
                            </div>
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate dark:text-white capitalize">
                                    {student.first_name + ' ' + student.middle_name + ' ' + student.last_name}
                                </p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {student.email}
                                </p>
                            </div>
                            
                        </div>
                    </li>)
                })}
            </ul> */}
      </div>
      <div
        className="absolute bottom-0  right-5 w-16 h-16 rounded-full shadow-xl bg-yellow-500 text-black hover:scale-110 duration-500 hover:cursor-pointer flex justify-center items-center"
        onClick={() => navigate("/admin/signup/student")}
      >
        <img src={plus} alt="add" className="w-8 h-8" />
      </div>
    </div>
  );
}

export default StudentsList;
