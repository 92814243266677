import { useState } from "react";
import Logo from "../assets/silicon-valley-logo.jpg";
import burger from "../assets/menu-icon.svg";
import close from "../assets/close-icon.svg";
import { NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";

function NavComponent({ options, selectedPage, setSelectedPage }) {
  const [isMenuToggled, setIsMenuToggled] = useState();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="py-3 flex justify-end items-center bg-black px-8 h-20">
      <div className="z-50 fixed top-2 left-8">
        <img
          src={Logo}
          alt="logo"
          className="w-32 h-32 rounded-full border-4 border-black"
        />
      </div>
      <nav className="flex space-x-5 text-sm">
        <div className="block md:hidden transition-all duration-500">
          {!isMenuToggled && (
            <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
              <img src={burger} alt="" />
            </button>
          )}

          {isMenuToggled && (
            <div>
              <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <img src={close} alt="" />
              </button>
              <div className="w-1/2 z-80 bg-slate-900 flex flex-col gap-4 fixed right-0 px-8 py-8 rounded-xl">
                {options.map((item) => {
                  const linkClasses = classNames(
                    "capitalize hover:text-yellow-500 transition duration-500 text-base text-white",
                    {
                      "text-yellow-500": window.location.pathname === item.link,
                    }
                  );

                  return (
                    <NavLink
                      key={item.link}
                      className={linkClasses}
                      to={item.link}
                    >
                      {item.name}
                    </NavLink>
                  );
                })}

                <hr className="my-2 -mx-2" />
                <button
                  onClick={logout}
                  className="text-white hover:text-yellow-500 transition-all duration-500"
                >
                  Log-out
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="hidden md:flex gap-8">
          {options.map((item) => {
            const linkClasses = classNames(
              "capitalize hover:text-yellow-500 transition duration-500 text-base text-white",
              {
                "text-yellow-500": window.location.pathname === item.link,
              }
            );

            return (
              <NavLink key={item.link} className={linkClasses} to={item.link}>
                {item.name}
              </NavLink>
            );
          })}

          <button
            onClick={logout}
            className="ml-4 -mt-1 bg-white hover:bg-yellow-500 transition-all duration-500 hover:scale-105 rounded-full py-2 px-4"
          >
            Log-out
          </button>
        </div>
      </nav>
    </div>
  );
}

export default NavComponent;
