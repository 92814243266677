import React, { useState } from 'react'
import Loader from '../components/Loader'
import { toast } from 'react-toastify'
import Axios  from 'axios'
import { useNavigate } from 'react-router-dom'
import getApiKey from '../apiKeys'

function CourseUpload() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        title: "",
        course_title: "",
        description: "",
        program: "tvet",
        file: null,
    })
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('token')
    const endpoint = getApiKey()
  

    const handleFileUpload = (e) => {
        
        setFormData((prevState) => ({
            ...prevState,
            file: e.target.files[0]
        }))

        // let fileReader = new FileReader();
        // fileReader.readAsDataURL(e.target.files[0]);

        // fileReader.onload = (event) => {
        //     setFormData((prevState) => ({
        //         ...prevState,
        //         file: event.target.result
        //     }))
        // }
    }

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = new FormData()
        data.append('title', formData.title);
        data.append('course_title', formData.course_title);
        data.append('description', formData.description);
        data.append('program', formData.program);
        data.append('file', formData.file);

        console.log(formData)
        try {
            const response = await Axios.post(`${endpoint}/api/upload-book`, data, { headers: {"Authorization" : `Bearer ${token}`} })
            console.log(response.data)
            toast.success("uploaded succefully")
            
        } catch (error) {
            console.group(error)
            toast.error(error.message)
        }
        
        setFormData({
            title: "",
            course_title: "",
            description: "",
            program: "tvet",
            file: null,
        })
        setLoading(false)
    }

  return loading ? <Loader /> : (
    <div className="flex flex-col justify-center my-20 mt-40">
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
            <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
                Upload a Book
            </h1>
            <form className="mt-6">
                <div className="mb-2">
                    <label
                        htmlFor="title"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Title
                    </label>
                    <input
                        id='title'
                        onChange={handleChange}
                        value={formData.title}
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="course_title"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Course Title
                    </label>
                    <input
                        id='course_title'
                        onChange={handleChange}
                        value={formData.course_title}
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="program"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Program
                    </label>
                    <select
                        id='program'
                        onChange={handleChange}
                        value={formData.program}
                        className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                        <option value="tvet">TVET</option>
                        <option value="degree">Bachelors</option>
                        <option value="masters">Masters</option>
                    </select>
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="description"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Descritpion
                    </label>
                    <textarea
                        onChange={handleChange}
                        id='description'
                        value={formData.description}
                        className="block w-full h-28 px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    > </textarea>
                </div>
                <div className="mt-8 mb-2">
                    <label
                        htmlFor="courseFile"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Upload the course file here:
                    </label>
                    <input
                        type="file"
                        id='courseFile'
                        onChange={handleFileUpload}
                        className="block w-full rounded-md border mt-2 file:bg-gradient-to-r file:from-red-500 file:to-yellow-500 file:text-white file:h-full file:px-4 file:py-1 file:border-none focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mt-16">
                    <button onClick={handleSubmit} className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none">
                        Upload
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default CourseUpload