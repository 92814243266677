import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import getApiKey from "../apiKeys";

function StudentSignupFromFile() {
  const endpoint = getApiKey()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    file: null,
  });
  const token = localStorage.getItem("token");

  const handleFileUpload = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("file", formData.file);

    try {
      const response = await Axios.post(
        `${endpoint}/api/excel-registation`,
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      toast.success("uploaded succefully");
      navigate("/admin/student/list");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }

    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col justify-center my-10 mt-40">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
          Student Sign Up from File
        </h1>
        <form className="mt-6">
          <div className="mt-8 mb-2">
            <label
              htmlFor="courseFile"
              className="block text-sm font-semibold text-gray-800"
            >
              Upload the Excel file here:
            </label>
            <input
              type="file"
              id="courseFile"
              onChange={handleFileUpload}
              className="block w-full rounded-md border mt-2 file:bg-gradient-to-r file:from-red-500 file:to-yellow-500 file:text-white file:h-full file:px-4 file:py-1 file:border-none focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <Link
            to="/admin/signup/student"
            className="text-xs text-red-500 hover:underline mt-12"
          >
            Add Student manually
          </Link>
          <div className="mt-4">
            <button
              onClick={handleSubmit}
              className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StudentSignupFromFile;
