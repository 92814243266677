import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import axios from "axios";
import getApiKey from "../apiKeys";

function CreateEvent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    images: [],
  });
  const token = localStorage.getItem("token");
  const endpoint = getApiKey();

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    console.log(files)
    setFormData((prevState) => ({
        ...prevState,
        images: files,
      }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append("title", formData.title);
    data.append("description", formData.description);

    formData.images.forEach((image) => {
      data.append("images[]", image);
    });

    console.log("data", data);

    try {
      const response = await axios.post(`${endpoint}/api/events`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("internal", response.data);
      toast.success("published succefully");
      navigate("/admin");
    } catch (error) {
      console.group(error);
      toast.error(error.message);
    }

    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col justify-center my-10 mt-40">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
          Create an Event Post
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label
              htmlFor="title"
              className="block text-sm font-semibold text-gray-800"
            >
              Event Title
            </label>
            <input
              id="title"
              onChange={handleChange}
              value={formData.title}
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="description"
              className="block text-sm font-semibold text-gray-800"
            >
              Descritpion
            </label>
            <textarea
              onChange={handleChange}
              id="description"
              value={formData.description}
              className="block w-full px-4 py-2 mt-2 h-28 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              {" "}
            </textarea>
          </div>
          <div className="mt-8 mb-2">
            <label
              htmlFor="courseFile"
              className="block text-sm font-semibold text-gray-800"
            >
              Upload Event Images:
            </label>
            <input
              type="file"
              id="courseFile"
              onChange={handleImageChange}
              multiple
              accept="image/*"
              className="block w-full rounded-md border mt-2 file:bg-gradient-to-r file:from-red-500 file:to-yellow-500 file:text-white file:h-full file:px-4 file:py-1 file:border-none focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <div className="mt-6">
            <button
              onClick={handleSubmit}
              className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
            >
              Publish
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateEvent;
