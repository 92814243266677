import React, { useState } from "react";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import getApiKey from "../apiKeys";

function StudentRegistration() {
  const endpoint = getApiKey();
  // const navigate = useNavigate()
  const [formData, setFormData] = useState({
    year: "1",
    semester: "1",
  });
  const [courses, setcourses] = useState([]);
  const [registered, setRegistered] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSelect = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelected((prev) => [...prev, value]);
    } else {
      setSelected((prev) => prev.filter((x) => x !== value));
    }
  };

  const getTotalCredit = () => {
    let total = 0;
    courses.forEach((item) => (total = total + Number(item.credit_hours)));
    return total.toString();
  };

  const getTotalEcts = () => {
    let total = 0;
    courses.forEach((item) => (total = total + Number(item.ects)));
    return total.toString();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);

    try {
      const response = await axios.get(
        `${endpoint}/api/course-for-registration/${formData.year}/${formData.semester}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      setRegistered(response.data.registered)
      if (response.data.courses) {
        setcourses(response.data.courses);
      } else {
        setcourses([])
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }

    setLoading(false);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setLoading(true);
    const request = {
      year: formData.year,
      semester: formData.semester,
      course_ids: selected,
    };
    console.log(request);
    try {
      const response = await axios.post(
        `${endpoint}/api/course-registration`,
        request,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      toast.success("Registered succefully");
      navigate("/student");
    } catch (error) {
      console.group(error);
      setSelected([]);
      toast.error(error.message);
    }

    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col justify-center min-h-screen my-10 mt-40 md:mt-20">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
          Registration Page
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label
              htmlFor="year"
              className="block text-sm font-semibold text-gray-800"
            >
              Year
            </label>
            <select
              id="year"
              onChange={handleChange}
              value={formData.year}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlFor="semester"
              className="block text-sm font-semibold text-gray-800"
            >
              Semester
            </label>
            <select
              id="semester"
              onChange={handleChange}
              value={formData.semester}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <div className="mt-6">
            <button
              onClick={handleSubmit}
              className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
            >
              Get Courses
            </button>
          </div>
        </form>
        {courses.length > 0 && (
          <div>
            <hr className="mt-6 mb-6 border-red-500" />
            <div>
              {!registered && <label className="block text-sm font-semibold text-gray-800 mb-4">
                Please select the courses you want to register to:
              </label>}
              {registered && <label className="block text-sm font-semibold text-gray-800 mb-4">
                Here are the registered Courses for the selecetd year and semister:
              </label>}
              <div className="grid grid-cols-12 gap-2 bg-slate-100 justify-center items-center block text-sm text-gray-800 py-2">
                <div></div>
                <p className="col-span-7">Courses</p>
                <p className="col-span-2"></p>
                <p className="col-span-2">Credit</p>
              </div>
              <div>
                {courses.map((item, i) => (
                  <div
                    key={i}
                    className="grid grid-cols-12 gap-2 justify-center items-center block text-sm text-gray-800 py-2"
                  >
                    {!registered && <input
                      className="w-4 h-4"
                      type="checkbox"
                      name="lang"
                      value={item.id}
                      onChange={handleSelect}
                    />}
                    {registered && <div></div>}
                    <p className="col-span-7">{item.course_name}</p>
                    <p className="col-span-2"></p>
                    <p className="col-span-2">{item.credit_hours}</p>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-12 gap-2 bg-slate-100 justify-center items-center block text-sm text-gray-800 py-2">
                <div></div>
                <p className="col-span-3">Total:</p>
                <p className="col-span-4"></p>
                <p className="col-span-2"></p>
                <p className="col-span-2">{getTotalCredit()}</p>
              </div>

              {!registered && <div className="mt-6">
                <button
                  onClick={handleRegistration}
                  className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
                >
                  Register
                </button>
              </div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentRegistration;
