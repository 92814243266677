import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import getApiKey from "../apiKeys";

function StudentSignup() {
  const endpoint = getApiKey()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    student_id: "",
    email: "",
    department: "Human Resource Management",
    year: "1",
    section: null,
    program: "TVET",
    password: "",
  });
  const token = localStorage.getItem("token");
  const departments = {
    TVET: [
      "Human Resource Management",
      "Accounting and Finance",
      "Marketing Sales and Management",
    ],
    Degree: ["Management", "Accounting and Finance"],
    Masters: ["Masters of Business Administration", "Project Management"],
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    if (e.target.id === "program") {
      setFormData((prevState) => ({
        ...prevState,
        department: departments[e.target.value][0],
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    try {
      const response = await Axios.post(
        `${endpoint}/api/student-registration`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log(response.data);
      toast.success("uploaded succefully");
      navigate("/admin/student/list");
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }

    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col justify-center my-10 mt-40">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
          Student Sign Up
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label
              htmlFor="first_name"
              className="block text-sm font-semibold text-gray-800"
            >
              First Name
            </label>
            <input
              onChange={handleChange}
              id="first_name"
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="middle_name"
              className="block text-sm font-semibold text-gray-800"
            >
              Middle Name
            </label>
            <input
              onChange={handleChange}
              id="middle_name"
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-semibold text-gray-800"
            >
              Last Name
            </label>
            <input
              onChange={handleChange}
              id="last_name"
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="student_id"
              className="block text-sm font-semibold text-gray-800"
            >
              ID
            </label>
            <input
              onChange={handleChange}
              id="student_id"
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <input
              onChange={handleChange}
              id="email"
              type="email"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="program"
              className="block text-sm font-semibold text-gray-800"
            >
              Program
            </label>
            <select
              id="program"
              onChange={handleChange}
              value={formData.program}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              <option value="TVET">TVET</option>
              <option value="Degree">Bachelors</option>
              <option value="Masters">Masters</option>
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlFor="department"
              className="block text-sm font-semibold text-gray-800"
            >
              Department
            </label>
            <select
              id="department"
              onChange={handleChange}
              value={formData.department}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              {departments[formData.program].map((dep) => {
                return (
                  <option key={dep} value={dep}>
                    {dep}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-2">
            <label
              htmlFor="year"
              className="block text-sm font-semibold text-gray-800"
            >
              Year
            </label>
            <select
              id="year"
              onChange={handleChange}
              value={formData.year}
              className="block w-full px-4 pl-2 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>
          {/* <div className="mb-2">
                    <label
                        htmlFor="year"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Year
                    </label>
                    <input
                        onChange={handleChange}
                        id='year'
                        type="number"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div> */}
          <div className="mb-2">
            <label
              htmlFor="section"
              className="block text-sm font-semibold text-gray-800"
            >
              Section
            </label>
            <input
              onChange={handleChange}
              id="section"
              type="number"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-semibold text-gray-800"
            >
              Password
            </label>
            <input
              onChange={handleChange}
              id="password"
              type="password"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>

          <Link
            to="/admin/signup/student/file"
            className="text-xs text-red-500 hover:underline mt-12"
          >
            Add Students from Excel File
          </Link>
          <div className="mt-4">
            <button
              onClick={handleSubmit}
              className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
            >
              Signup
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default StudentSignup;
