import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import frontImage from "../undraw_education_f8ru.svg";
import value from "../assets/undraw_graduation_re_gthnV.svg";
import vission from "../assets/undraw_business_plan_re_0v81.svg";
import mission from "../assets/undraw_educator_re_ju47.svg";
import graduation1 from "../assets/SVGRepo_iconCarrier.svg";
import graduation2 from "../assets/SVGRepo_iconCarrier (1).svg";
import graduation3 from "../assets/SVGRepo_iconCarrier (2).svg";
import seminar1 from "../assets/seminar1.jpg";
import seminar2 from "../assets/seminar2.jpg";
import seminar3 from "../assets/seminar3.jpg";
import svcRooms from "../assets/svc-rooms.jpg";
import svcLab from "../assets/svs-lab.jpg";
import Research from '../assets/research.jpg';
import Person1 from "../assets/person-1.jpeg";
import Person2 from "../assets/person-2.jpeg";
import Person3 from "../assets/person-3.jpeg";
import Bell from "../assets/bell.svg";

import Card from "../components/Card";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import Modal from "../components/Modal";
import getApiKey from "../apiKeys";
import Loader from "../components/Loader";
import axios from "axios";

import { useForm } from "react-hook-form";

export default function Home({ selectedPage, setSelectedPage }) {
  const [modalOpen, setModalOpen] = useState();
  const settings = {
    dots: true, // Show pagination dots
    infinite: true, // Allow infinite scrolling
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll per swipe
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Delay between slides in milliseconds
  };
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const endpoint = getApiKey();

  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    console.log("~ e", e);
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      const response = await axios.get(`${endpoint}/api/events`);
      setEvents(response.data);
      console.log(response.data);
      setLoading(false);
    };

    fetchEvents();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="min-h-screen flex flex-col">
      <div
        className="fixed bottom-20 md:bottom-24 right-5 w-16 h-16 rounded-full shadow-xl bg-yellow-500 text-black md:hover:scale-110 duration-500 hover:cursor-pointer flex justify-center items-center z-1000"
        onClick={modalOpen ? close : open}
      >
        <img src={Bell} alt="announcements" className="w-8 h-8" />
      </div>

      <div id="dialogflow-chat-widget-container"></div>

      <header className="w-full z-40 fixed top-0 h-40">
        <NavBar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      </header>

      <main className="flex-auto">
        <section id="home" className="grid md:grid-cols-2 pt-40  mt-10">
          <div className=" flex flex-col items-center mt-6">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 1.3 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <h1 className=" text-6xl font-semibold">Silicon Valley</h1>
              <h1 className=" text-6xl font-semibold">College</h1>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.4, duration: 1.3 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <p className="text-slate-500 text-2xl italic mt-16 text-center">
                “The place where you get quality education”
              </p>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ delay: 0.8, duration: 1.3 }}
              variants={{
                hidden: { opacity: 0, x: -50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <Link to="/login">
                <button className="z-0 rounded-full px-16 py-5 bg-gradient-to-r from-red-500 to-yellow-500 hover:scale-105 transform transition-all duration-500 text-white mt-20">
                  Get Started
                </button>
              </Link>
            </motion.div>
          </div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 2 }}
            variants={{
              hidden: { opacity: 0, y: 100 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <div className="hidden md:block">
              <img src={frontImage} alt="" className="w-[500px]" />
            </div>
          </motion.div>
        </section>

        <section id="about us" className="">
          <div className="w-11/12 md:w-2/3 pt-40 mx-auto py-10">
            <p className="text-6xl font-inter mb-10 font-bold">About Us</p>

            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 flex flex-col">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="text-xl text-red-400 font-bold">Our Story</p>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="mt-5">
                    Silicon Valley College in Addis Ababa has been a trailblazer
                    in education for the past six years, dedicated to empowering
                    students with cutting-edge knowledge and skills in
                    technology and business. We have proudly graduated over
                    5,000 students who have gone on to make significant
                    contributions in various sectors. Currently, we are
                    nurturing over 700 students, equipping them to excel in the
                    competitive global market.
                  </p>
                </motion.div>
              </div>
              <div className="md:w-1/2 flex flex-col space-y-10">
                <div className="grid grid-cols-12 gap-10">
                  <div className="md:col-span-2"></div>

                  <div className="col-span-12 md:col-span-10">
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      transition={{ duration: 1.3 }}
                      variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                      }}
                    >
                      <p className="text-3xl font-bold">
                        6 years on the market
                      </p>
                      <p className="mt-2 text-slate-500 text-sm">
                        Silicon Valley College officially opened in 2018,
                        marking six successful years of excellence and growth in
                        education.
                      </p>
                    </motion.div>
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-10">
                  <div className="md:col-span-2"></div>
                  <div className="col-span-12 md:col-span-10">
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      transition={{ delay: 0.4, duration: 1.3 }}
                      variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                      }}
                    >
                      <p className="text-3xl font-bold">
                        More than 5,000 students Graduated
                      </p>
                      <p className="mt-2 text-slate-500 text-sm">
                        Over the years, we have successfully graduated more than
                        5,000 students, each embarking on promising new careers.
                      </p>
                    </motion.div>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-10">
                  <div className="md:col-span-2"></div>
                  <div className="col-span-12 md:col-span-10">
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.5 }}
                      transition={{ delay: 0.8, duration: 1.3 }}
                      variants={{
                        hidden: { opacity: 0, x: 50 },
                        visible: { opacity: 1, x: 0 },
                      }}
                    >
                      <p className="text-3xl font-bold">
                        Currently Serving 700+ Students
                      </p>
                      <p className="mt-2 text-slate-500 text-sm">
                        At present, our institution is proudly serving a growing
                        community of more than 700 dedicated and enthusiastic
                        students.
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full mt-32 flex flex-col gap-5 md:gap-0 md:flex-row items-center md:space-x-20">
              <div className="md:w-1/2">
                <motion.div
                  className="bg-slate-50 rounded-xl p-2"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <img
                    src={mission}
                    alt=""
                    className="w-full h-72 rounded-xl"
                  />
                </motion.div>
              </div>
              <div className="md:w-1/2 flex flex-col items-start gap-3 md:gap-8">
                <motion.div
                  className="flex flex-col items-start gap-3 md:gap-8"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="text-xl font-bold uppercase">our mission</p>
                  <p className="text-slate-500">
                    Our mission is to deliver world-class education and training
                    in technology and business, fostering innovative leaders and
                    skilled professionals ready to address contemporary
                    challenges, by providing a comprehensive curriculum,
                    state-of-the-art facilities, and a supportive learning
                    environment that encourages creativity, critical thinking,
                    and practical application, ensuring our graduates are
                    well-prepared to excel in a rapidly evolving global
                    landscape.
                  </p>
                </motion.div>
              </div>
            </div>

            <div className="w-full mt-32 flex flex-col-reverse gap-5 md:gap-0 md:flex-row items-center md:space-x-20">
              <div className="md:w-1/2 flex flex-col items-start gap-3 md:gap-8">
                <motion.div
                  className="flex flex-col items-start gap-3 md:gap-8"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="text-xl font-bold uppercase">our Vision</p>
                  <p className="text-slate-500">
                    We envision becoming the premier educational institution in
                    Ethiopia, renowned for excellence in teaching, research, and
                    community engagement, by fostering a culture of innovation
                    and continuous learning, where students and faculty
                    collaborate to advance knowledge, address societal
                    challenges, and contribute to sustainable development,
                    ensuring our graduates are equipped with the skills and
                    mindset to thrive in an ever-changing world.
                  </p>
                </motion.div>
              </div>
              <div className="md:w-1/2">
                <motion.div
                  className="bg-slate-50 rounded-xl p-2"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <img
                    src={vission}
                    alt=""
                    className="w-full h-72 rounded-xl"
                  />
                </motion.div>
              </div>
            </div>

            <div className="w-full mt-32 flex flex-col gap-5 md:gap-0 md:flex-row items-center md:space-x-20">
              <div className="md:w-1/2">
                <motion.div
                  className="bg-slate-50 rounded-xl p-2"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <img src={value} alt="" className="w-full h-72  rounded-xl" />
                </motion.div>
              </div>
              <div className="md:w-1/2 flex flex-col items-start gap-3 md:gap-8">
                <motion.div
                  className="flex flex-col items-start gap-3 md:gap-8"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="text-xl font-bold uppercase">our value</p>
                  <p className="text-slate-500">
                    Our core values include a commitment to{" "}
                    <i className="font-bold">excellence</i>, ensuring the
                    highest standards in education and training. We emphasize{" "}
                    <i className="font-bold">innovation</i> by encouraging
                    creative thinking and groundbreaking solutions.{" "}
                    <i className="font-bold">Integrity</i> is paramount, as we
                    uphold ethical standards in all our endeavors. Additionally,
                    we prioritize fostering a supportive and inclusive{" "}
                    <i className="font-bold">community</i> environment for all,
                    promoting collaboration and mutual respect among students,
                    faculty, and staff.
                  </p>
                </motion.div>
              </div>
            </div>

            <div className="mt-32">
              <p className="text-4xl text-center font-inter mb-10 font-bold">
                Our Programs
              </p>
              <div className="flex flex-col md:flex-row gap-5">
                <div className="w-3/4">
                  <motion.div
                    className="flex flex-col items-start gap-3 md:gap-8"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{ duration: 1.3 }}
                    variants={{
                      hidden: { opacity: 0, scale: 0.95 },
                      visible: { opacity: 1, scale: 1 },
                    }}
                  >
                    <Card
                      image={graduation1}
                      title="TVET"
                      details="Our Technical and Vocational Education and Training (TVET) programs are designed to provide each students with hands-on skills and practical experience across the different fields, ensuring that they are well-prepared for immediate employment and career advancement. These comprehensive programs emphasize real-world applications and industry-relevant training, enabling our graduates to excel in their chosen trades, professions, entrepreneurial endeavors, and diverse, rewarding career pathways in various industries"
                    />
                  </motion.div>
                </div>
                <div className="w-3/4">
                  <motion.div
                    className="flex flex-col items-start gap-3 md:gap-8"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{ delay: 0.4, duration: 1.3 }}
                    variants={{
                      hidden: { opacity: 0, scale: 0.95 },
                      visible: { opacity: 1, scale: 1 },
                    }}
                  >
                    <Card
                      image={graduation2}
                      title="Undergraduate"
                      details="Our undergraduate programs offer a comprehensive education that combines theoretical knowledge with practical application across various disciplines. We currently offer Bachelor of Arts (BA) programs in Accounting and Finance, and Management. These programs are designed to equip students with the foundational skills and knowledge needed to succeed in their professional careers, fostering critical thinking, problem-solving abilities, and a deep understanding of their respective fields."
                    />
                  </motion.div>
                </div>
                <div className="w-3/4">
                  <motion.div
                    className="flex flex-col items-start gap-3 md:gap-8"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{ delay: 0.8, duration: 1.3 }}
                    variants={{
                      hidden: { opacity: 0, scale: 0.95 },
                      visible: { opacity: 1, scale: 1 },
                    }}
                  >
                    <Card
                      image={graduation3}
                      title="graduate"
                      details="Our graduate programs focus on advanced study and research, providing students with the insights needed for leadership roles in their chosen fields. We currently offer a Master of Arts in Business Administration, a program that prepares students for strategic management roles, enhancing their analytical, decision-making, and leadership skills. Our graduate curriculum is designed to support professional growth and development, enabling students to make significant contributions to their industries and communities."
                    />
                  </motion.div>
                </div>
              </div>
            </div>

            <div className="w-full mt-32 flex flex-col gap-5 md:gap-0 md:flex-row items-center md:space-x-20">
              <div className="md:w-1/2">
                <motion.div
                  className="bg-slate-50 rounded-xl p-2"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <img src={svcLab} alt="" className="w-full h-72 rounded-xl" />
                </motion.div>
              </div>
              <div className="md:w-1/2 flex flex-col items-start gap-3 md:gap-8">
                <motion.div
                  className="flex flex-col items-start gap-3 md:gap-8"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="text-xl font-bold uppercase">
                    Our Laboratories
                  </p>
                  <p className="text-slate-500">
                    Our cutting-edge laboratories, outfitted with the latest
                    technology, offer students invaluable practical experience
                    essential for their future careers. These state-of-the-art
                    facilities provide hands-on learning opportunities that
                    bridge the gap between theoretical knowledge and real-world
                    application, ensuring our graduates are well-prepared for
                    the demands of their chosen fields. This approach fosters
                    innovation, critical thinking, and technical proficiency,
                    setting our students apart in the competitive job market.
                  </p>
                </motion.div>
              </div>
            </div>

            <div className="w-full mt-32 flex flex-col-reverse gap-5 md:gap-0 md:flex-row items-center md:space-x-20">
              <div className="md:w-1/2 flex flex-col items-start gap-3 md:gap-8">
                <motion.div
                  className="flex flex-col items-start gap-3 md:gap-8"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="text-xl font-bold uppercase">Our Class Rooms</p>
                  <p className="text-slate-500">
                    Our modern classrooms are crafted to foster a conducive
                    learning environment, promoting interaction, collaboration,
                    and effective learning. These spaces encourage student
                    engagement through innovative designs and flexible layouts,
                    enhancing both individual and group activities. By
                    integrating advanced technology and ergonomic furniture, our
                    classrooms create an atmosphere that supports diverse
                    learning styles, ensuring that every student can thrive
                    academically and socially.
                  </p>
                </motion.div>
              </div>
              <div className="md:w-1/2">
                <motion.div
                  className="bg-slate-50 rounded-xl p-2"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <img
                    src={svcRooms}
                    alt=""
                    className="w-full h-72 rounded-xl"
                  />
                </motion.div>
              </div>
            </div>

            <div className="w-full mt-32 flex flex-col gap-5 md:gap-0 md:flex-row items-center md:space-x-20">
              <div className="md:w-1/2">
                <motion.div
                  className="bg-slate-50 rounded-xl p-2"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <img src={value} alt="" className="w-full h-72  rounded-xl" />
                </motion.div>
              </div>
              <div className="md:w-1/2 flex flex-col items-start gap-3 md:gap-8">
                <motion.div
                  className="flex flex-col items-start gap-3 md:gap-8"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="text-xl font-bold uppercase">our Graduates</p>
                  <p className="text-slate-500">
                    Our graduates, equipped with skills and knowledge from
                    Silicon Valley College, excel in various industries
                    worldwide. They are recognized for their expertise,
                    professionalism, and innovative thinking, driving positive
                    change in their organizations. Benefiting from free soft
                    skills and entrepreneurial training, they gain essential
                    communication, leadership, and problem-solving abilities.
                    This comprehensive approach ensures our alumni are
                    well-rounded professionals, ready to tackle modern workforce
                    challenges.
                  </p>
                </motion.div>
              </div>
            </div>

            <div className="w-full mt-32 flex flex-col-reverse gap-5 md:gap-0 md:flex-row items-center md:space-x-20">
              <div className="md:w-1/2 flex flex-col items-start gap-3 md:gap-8">
                <motion.div
                  className="flex flex-col items-start gap-3 md:gap-8"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: -50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <p className="text-xl font-bold uppercase">
                    Our Research and Analytics
                  </p>
                  <p className="text-slate-500">
                    At Silicon Valley College, we advance knowledge through
                    rigorous research and analytics. Our dedicated team pioneers
                    projects that offer valuable insights in education,
                    technology, business innovation, AI, and leadership
                    development. By generating cutting-edge knowledge, we inform
                    best practices, shape policy, and drive progress, fostering
                    a culture of continuous improvement and intellectual
                    curiosity for a positive societal impact.
                  </p>
                </motion.div>
              </div>
              <div className="md:w-1/2">
                <motion.div
                  className="bg-slate-50 rounded-xl p-2"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, x: 50 },
                    visible: { opacity: 1, x: 0 },
                  }}
                >
                  <img
                    src={Research}
                    alt=""
                    className="w-full h-72 rounded-xl"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        <section id="admissions">
          <div className="w-11/12 md:w-2/3 pt-40 mx-auto py-10">
            <p className="text-6xl font-inter mb-10 font-bold">
              Admission Criterias
            </p>
            <div className="mt-16">
              <div className="grid md:grid-cols-2 gap-8 md:gap-10">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sint cum, dignissimos eveniet et debitis expedita
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sint cum, dignissimos eveniet et debitis expedita
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.3, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sint cum, dignissimos eveniet et debitis expedita
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.3, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sint cum, dignissimos eveniet et debitis expedita
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.6, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sint cum, dignissimos eveniet et debitis expedita
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.6, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Sint cum, dignissimos eveniet et debitis expedita
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        <section id="staffs">
          <div className="w-11/12 md:w-2/3 pt-40 mx-auto py-10">
            <p className="text-6xl font-inter mb-10 font-bold">
              Our Staff Members
            </p>
            <div className="flex flex-col md:flex-row gap-5 mt-16">
              `
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 1.3 }}
                variants={{
                  hidden: { opacity: 0, scale: 0.95 },
                  visible: { opacity: 1, scale: 1 },
                }}
              >
                <Card
                  image={Person1}
                  title="Dr. Tewodros"
                  details="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto nesciunt, sequi similique in eius labore dolores amet nemo id delectus reprehenderit quasi iste illo maxime vero sunt porro eum aperiam."
                />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                transition={{ delay: 0.4, duration: 1.3 }}
                variants={{
                  hidden: { opacity: 0, scale: 0.95 },
                  visible: { opacity: 1, scale: 1 },
                }}
              >
                <Card
                  image={Person2}
                  title="Dr. Geletaw"
                  details="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto nesciunt, sequi similique in eius labore dolores amet nemo id delectus reprehenderit quasi iste illo maxime vero sunt porro eum aperiam."
                />
              </motion.div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                transition={{ delay: 0.8, duration: 1.3 }}
                variants={{
                  hidden: { opacity: 0, scale: 0.95 },
                  visible: { opacity: 1, scale: 1 },
                }}
              >
                <Card
                  image={Person3}
                  title="Mr. Henok"
                  details="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto nesciunt, sequi similique in eius labore dolores amet nemo id delectus reprehenderit quasi iste illo maxime vero sunt porro eum aperiam."
                />
              </motion.div>
            </div>
          </div>
        </section>

        <section id="events">
          {events.length !== 0 && (
            <div className="w-11/12 md:w-2/3 pt-40 mx-auto py-10">
              <p className="text-6xl font-inter mb-24 font-bold">
                Latest Events in our campus
              </p>
              {/* <div className="w-full">
                <Slider {...settings}>
                  <div className="relative">
                    <img
                      src={seminar1}
                      alt="Slide 1"
                      className=" rounded-xl h-[500px] w-full mx-auto"
                    />
                    <div className="absolute top-0 left-0 w-full h-full z-40 hover:bg-black hover:bg-opacity-40 transition-all duration-300">
                      <div className="flex flex-col w-full h-full justify-end py-4 px-8 gap-4">
                        <h1 className="text-3xl text-white font-semibold">
                          Event title Here
                        </h1>
                        <p className="text-sm text-slate-200">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Qui, non. Laboriosam, quo quibusdam voluptates
                          cum earum unde? Quis illum ipsam in ducimus quae. Sint
                          porro iste ipsum dolores eaque minima!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <img
                      src={seminar2}
                      alt="Slide 2"
                      className=" rounded-xl h-[500px] w-full mx-auto"
                    />
                    <div className="absolute top-0 left-0 w-full h-full z-40 hover:bg-black hover:bg-opacity-40 transition-all duration-300">
                      <div className="flex flex-col w-full h-full justify-end py-4 px-8 gap-4">
                        <h1 className="text-3xl text-white font-semibold">
                          Event title Here
                        </h1>
                        <p className="text-sm text-slate-200">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Qui, non. Laboriosam, quo quibusdam voluptates
                          cum earum unde? Quis illum ipsam in ducimus quae. Sint
                          porro iste ipsum dolores eaque minima!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <img
                      src={seminar3}
                      alt="Slide 3"
                      className=" rounded-xl h-[500px] w-full mx-auto"
                    />
                    <div className="absolute top-0 left-0 w-full h-full z-40 hover:bg-black hover:bg-opacity-40 transition-all duration-300">
                      <div className="flex flex-col w-full h-full justify-end py-4 px-8 gap-4">
                        <h1 className="text-3xl text-white font-semibold">
                          Event title Here
                        </h1>
                        <p className="text-sm text-slate-200">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Qui, non. Laboriosam, quo quibusdam voluptates
                          cum earum unde? Quis illum ipsam in ducimus quae. Sint
                          porro iste ipsum dolores eaque minima!
                        </p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div> */}

              <div className="w-full relative">
                <Slider {...settings}>
                  {events.map((event) => {
                    return event.images.map((image) => (
                      <div className="relative">
                        <img
                          src={`${endpoint}${image}`}
                          alt="Slide"
                          className=" rounded-xl h-[500px] w-full mx-auto"
                        />
                        <div className="absolute top-0 left-0 w-full h-full z-40 hover:bg-black hover:bg-opacity-40 transition-all duration-300">
                          <div className="flex flex-col w-full h-full justify-end py-4 px-8 gap-4">
                            <h1 className="text-3xl text-white font-semibold">
                              {event.title}
                            </h1>
                            <p className="text-sm text-slate-200">
                              {event.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ));
                  })}
                </Slider>
              </div>
            </div>
          )}
        </section>

        <section id="careers">
          <div className="w-11/12 md:w-2/3 pt-40 mx-auto py-10">
            <p className="text-6xl font-inter mb-10 font-bold">
              Employement Criterias
            </p>
            <div className="mt-16">
              <div className="grid md:grid-cols-2 gap-8 md:gap-10">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      A minimum of a Master’s degree in a relevant field (e.g.,
                      Artificial Intelligence, Business Administration, Computer
                      Science).
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      A minimum of 5 years of hands-on experience in the
                      relevant field is required, along with a demonstrated
                      history of this experience.
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.3, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Proficiency in the latest tools and technologies, with the
                      ability to develop and deliver engaging, interactive
                      training sessions using various instructional methods.
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.3, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Excellent verbal and written communication skills, with
                      the ability to convey complex concepts clearly and foster
                      a collaborative learning environment through strong
                      interpersonal skills.
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.6, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Experience in designing and developing training materials
                      and curriculum, with the ability to tailor programs to
                      meet the specific needs of different organizations and
                      participants.
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.6, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Strong problem-solving and analytical skills, with the
                      ability to conduct needs assessments and evaluate training
                      effectiveness using various metrics and feedback
                      mechanisms.
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.6, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Strong organizational and time management skills, high
                      professionalism and ethical standards, and a passion for
                      teaching with a commitment to student success.
                    </p>
                  </div>
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.6, duration: 1.3 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <div className="w-full flex items-center gap-5 group">
                    <div className="w-[12px] h-[12px] bg-gradient-to-r from-red-500 to-yellow-500 rounded-full"></div>
                    <p className="w-10/12">
                      Availability for scheduled training sessions, flexibility
                      to adapt content based on feedback and needs, and a
                      commitment to continuous professional development.
                    </p>
                  </div>
                </motion.div>
              </div>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.8, duration: 1.3 }}
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <div className=" flex justify-center items-center">
                  <Link to={"apply/teacher"}>
                    <button className="z-0 rounded-full px-16 py-5 bg-gradient-to-r from-red-500 to-yellow-500 hover:scale-105 transform transition-all duration-500 text-white mt-20">
                      Apply to become a Teacher
                    </button>
                  </Link>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section id="contact us">
          <div className="w-10/12 pt-40 mx-auto py-10 flex flex-col md:flex-row gap-16">
            <motion.div
              className="flex-1"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 1.3 }}
              variants={{
                hidden: { opacity: 0, scale: 0.95 },
                visible: { opacity: 1, scale: 1 },
              }}
            >
              <iframe
                className="w-full rounded-xl"
                title="address"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.9081050442514!2d38.75518557472648!3d8.980603391078771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85542dc201d7%3A0xef0ddd925c3c1f91!2sSilicon%20Valley%20College!5e0!3m2!1sen!2set!4v1682103070604!5m2!1sen!2set"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </motion.div>

            <motion.div
              className="flex-1"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.3 }}
              transition={{ duration: 1.3 }}
              variants={{
                hidden: { opacity: 0, scale: 0.95 },
                visible: { opacity: 1, scale: 1 },
              }}
            >
              <div className="w-full mx-auto py-10">
                <p className="text-6xl font-inter mb-10 font-bold">
                  Contact Us
                </p>
                <div className="mt-16">
                  <form
                    target="_blank"
                    onSubmit={onSubmit}
                    action="https://formsubmit.co/pauldessie@gmail.com"
                    method="POST"
                    className="flex flex-col gap-5 md:w-10/12"
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="Name"
                        className="w-full py-1 px-2 outline outline-slate-300"
                        {...register("name", {
                          required: true,
                          maxLength: 100,
                        })}
                      />
                      {errors.name && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.name.type === "required" &&
                            "This field is required."}
                          {errors.name.type === "maxLength" &&
                            "Max length is 100 char."}
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Email"
                        className="w-full py-1 px-2 outline outline-slate-300"
                        {...register("email", {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        })}
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.email.type === "required" &&
                            "This field is required."}
                          {errors.email.type === "pattern" &&
                            "Invalid email address."}
                        </p>
                      )}
                    </div>
                    <div>
                      <textarea
                        name="message"
                        id=""
                        className="w-full h-24 py-1 px-2 outline outline-slate-300"
                        placeholder="Type your message here..."
                        {...register("message", {
                          required: true,
                          maxLength: 2000,
                        })}
                      ></textarea>
                      {errors.message && (
                        <p className="text-red-500 text-xs mt-1">
                          {errors.message.type === "required" &&
                            "This field is required."}
                          {errors.message.type === "maxLength" &&
                            "Max length is 2000 char."}
                        </p>
                      )}
                    </div>
                    <button className="self-end py-2 px-4 mt-8 w-36 rounded-full bg-gradient-to-r from-red-500 to-yellow-500 hover:scale-105 transform transition-all duration-500 text-white">
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        {modalOpen && <Modal modalOpen={modalOpen} handleClose={close} />}
      </main>

      <footer className="w-full bg-black py-10 px-24">
        <Footer selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      </footer>
    </div>
  );
}
