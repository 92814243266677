import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ListItem from '../components/ListItem';
import Loader from '../components/Loader';
import getApiKey from '../apiKeys';

function Announcements() {
    const [course, setCourse] = useState([])
    const [loading, setLoading] = useState(true)
    const endpoint = getApiKey()
    
    useEffect(() => {
        setLoading(true)
        const fetchBooks = async () => {
            const response = await axios.get(`${endpoint}/api/announcements`)
            setCourse(response.data)
            console.log(course)
        }

        fetchBooks()
        setLoading(false)
    }, [])

  return loading ? <Loader /> : (
    <div className='w-full mx-auto'>
        <p className='text-xl md:text-3xl font-inter mb-10 font-bold'>Announcements</p>

        <div className='w-full mt-16'>
            <div className='w-full flex flex-col gap-8'>
                {course.map((item) => <ListItem key={item.id} title={item.title} detail={item.description} />)}
            </div>
        </div>
    </div>
  )
}

export default Announcements