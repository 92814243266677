import React from 'react'

import Footer from '../components/Footer';
import NavComponent from '../components/NavComponent';
import {  Outlet } from 'react-router-dom';



function AdminMainPage({selectedPage, setSelectedPage}) {

    const options = [
      {
        name: 'Home',
        link: '/admin'
      },
      {
        name: 'profile',
        link: '/admin/profile'
      },
      {
        name: 'teacher',
        link: '/admin/teacher/list'
      },
      {
        name: 'student',
        link: '/admin/student/list'
      },
      {
        name: 'add course',
        link: '/admin/create/course'
      },
      {
        name: 'registration',
        link: '/admin/create/course-registration'
      },
      {
        name: 'announcement',
        link: '/admin/create/announcement'
      },
      {
        name: 'event',
        link: '/admin/create/event'
      },
      {
        name: 'library',
        link: '/admin/add/books'
      },
      {
        name: 'assign',
        link: '/admin/assign'
      },
    ]
    
    
    return (
      <div className="min-h-screen flex flex-col">
        <header className="w-full z-40 fixed top-0 h-40">
          <NavComponent options={options} selectedPage={selectedPage} setSelectedPage = {setSelectedPage}/>
        </header>
  
        <main className="flex-auto">  
          <Outlet />  
        </main>
  
        <footer className="w-full bg-black py-10 px-24">
          <Footer selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>
        </footer>
      </div>
    )
  }

export default AdminMainPage