import React, { useEffect, useMemo, useState } from "react";
import pfp from "../assets/Default_pfp.svg";
import deleteSvg from "../assets/delete.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import FilterComponent from "../components/FilterComponent";
import DataTable from "react-data-table-component";
import getApiKey from "../apiKeys";
import { toast } from "react-toastify";

function LibraryBooks() {
    const navigate = useNavigate();
    const [books, setbooks] = useState([]);
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);
    const endpoint = getApiKey();
  
    const columns = [
      {
        name: "Title",
        selector: (book) => book.title,
        sortable: true,
      },
      
      {
        name: "Author(s)",
        selector: (book) => book.author,
        sortable: true,
      },
      
      {
        name: "Subject",
        selector: (book) => book.subject,
        sortable: true,
      },
      
      {
        name: "Edition",
        selector: (book) => book.edition,
        sortable: true,
      },
      
    ];
  
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText("");
        }
      };
  
      return (
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      );
    }, [filterText, resetPaginationToggle]);
  
    useEffect(() => {
      const fetchbooks = async () => {
        const response = await axios.get(`${endpoint}/api/hard-copy-books`, {
          headers: { Authorization: `Bearer ${token}` },
        }); 
        setbooks(response.data);
        console.log("books", response.data);
        setLoading(false);
      };
  
      setLoading(true);
      fetchbooks();
    }, []);
  

    return loading ? (
      <Loader />
    ) : (
      <div className="relative flex flex-col justify-center my-10 mt-40">
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4">
          <DataTable
            title="Books avialable in The Library"
            columns={columns}
            data={books.filter((item) => {
              if (filterText === "") {
                return item;
              } else if (
                item.title
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                item.author
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                item.subject.toLowerCase().includes(filterText.toLowerCase())
              ) {
                return item;
              }
            })}
            progressPending={loading}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        </div>
      </div>
    );
  }

export default LibraryBooks