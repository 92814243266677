import React from "react";

const Card = ({ image, title, details }) => {
  return (
    <div className="w-full p-8 rounded-xl shadow-md transform transition-all hover:scale-105 duration-500">
      <div className="flex flex-col items-center justify-center text-center max-w-5x1 mx-auto gap-8">
        <img src={image} alt="pic" className="p-8 w-60 h-60" />
        <div className="card-content">
          <h2 className="card-title uppercase text-x1 font-bold">{title}</h2>
          <p className="card-details text-sm leading-7 my-3 font-light opacity-50">{details}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;