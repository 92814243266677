import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import ListItem from '../components/ListItem';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import getApiKey from '../apiKeys';
import FilterComponent from "../components/FilterComponent";
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';

function GetAssignedCourses() {
  const endpoint = getApiKey()
  const [courses, setCourses] = useState([])
  const [names, setNames] = useState({})
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem('token')
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


  const columns = [
    {
      name: "ID",
      selector: (course) => course.course_id,
      sortable: true,
    },
    {
      name: "Course Name",
      selector: (course) => course.course_name,
      sortable: true,
    },
    {
      name: "Program",
      selector: (course) => course.program,
      sortable: true,
    },
    {
      name: "Department",
      selector: (course) => course.department,
      sortable: true,
    },
    {
      name: "Year",
      selector: (course) => course.year,
      sortable: true,
    },
    {
      name: "Section",
      selector: (course) => course.section,
      sortable: true,
    },
    {
      name: "Mark List",
      selector: (course) => (
        <Link
          to={`/teacher/courses/list/${course.course_id}/${course.section}`}
          className='hover:text-blue-500 hover:underline'
        >
          Link
        </Link>
      ),
      sortable: true,
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

    useEffect(() => {
        setLoading(true)
        const fetchBooks = async () => {
          try {
            const response = await axios.get(`${endpoint}/api/assigned-courses`, { headers: {"Authorization" : `Bearer ${token}`} })
            setCourses(response.data) 
            
          } catch (error) {
            console.group(error)
            toast.error(error.response.data.message)
          }
        }

        fetchBooks()
        setLoading(false)
    }, [])

  return loading ? <Loader /> : (
    <div className=" relative flex flex-col justify-center my-10 mt-24">

      <div className="w-full mt-5 p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4">

        {courses && (
          <DataTable
            id="table"
            title="Assigned Courses"
            columns={columns}
            data={courses.filter((item) => {
              if (filterText === "") {
                return item;
              } else if (
                item.program
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                item.department
                  .toLowerCase()
                  .includes(filterText.toLowerCase()) ||
                  item.course_name
                    .toLowerCase()
                    .includes(filterText.toLowerCase())
              ) {
                return item;
              }
            })}
            progressPending={loading}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
          />
        )}

      </div>
    </div>
  )
}

export default GetAssignedCourses