import React, { useEffect, useState } from 'react'
import Book from "../assets/book.jpeg"
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from '../components/Loader'
import getApiKey from '../apiKeys';

function BookDetail() {
    const endpoint = getApiKey()
  const params = useParams()
    const [book, setBook] = useState({})
    const [loading, setLoading] = useState(true)
    const token = localStorage.getItem('token')

    useEffect(() => {
        const fetchBooks = async () => {
            const response = await axios.get(`${endpoint}/api/books/${params.id}`, { headers: {"Authorization" : `Bearer ${token}`} })
            setBook(response.data)
            console.log(book)
        }

        fetchBooks()
        setLoading(false)
    }, [])

  return loading ? <Loader /> : (
    <section className="text-gray-700 body-font overflow-hidden bg-white my-10 mt-20">
        <div className="container px-5 py-24 mx-auto">
            <div className="lg:w-4/5 mx-auto flex flex-wrap">
                <img alt="pdf file" className="w-full md:w-[450px] object-cover object-center rounded border border-gray-200 my-2" src={Book}/>
                <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                    <h2 className="text-sm title-font text-gray-500 tracking-widest">{book.program}</h2>
                    <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">{book.title}</h1>
                    <p className="leading-relaxed mt-6">{book.description}</p>
                    <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
                    </div>
                    <div className="flex">
                    <button className="flex ml-auto text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded"><a href={`${endpoint}${book.file}`}>Download</a></button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BookDetail