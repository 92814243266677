import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import getApiKey from "../apiKeys";

function TeacherRegistrationForm() {
  const endpoint = getApiKey();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    institution: "",
    specialization: "",
    academic_rank: "",
    // password: "",
    resume: null,
  });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleFileUpload = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      resume: e.target.files[0],
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const data = new FormData();
    data.append('first_name', formData.first_name);
    data.append('middle_name', formData.middle_name);
    data.append('last_name', formData.last_name);
    data.append('email', formData.email);
    data.append('phone_number', formData.phone_number);
    data.append('institution', formData.institution);
    data.append('specialization', formData.specialization);
    data.append('academic_rank', formData.academic_rank);
    data.append('resume', formData.resume);
    
    try {
      const response = await Axios.post(
        `${endpoint}/api/careers`,
        data
      );
      console.log(response.data);
      toast.success("Submitted succefully");
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }

    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="flex flex-col justify-center my-10">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
          Teacher Application Form
        </h1>
        <form className="mt-6">
          <div className="mb-2">
            <label
              htmlFor="first_name"
              className="block text-sm font-semibold text-gray-800"
            >
              First Name
            </label>
            <input
              onChange={handleChange}
              id="first_name"
              value={formData.first_name}
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="middle_name"
              className="block text-sm font-semibold text-gray-800"
            >
              Middle Name
            </label>
            <input
              onChange={handleChange}
              id="middle_name"
              value={formData.middle_name}
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="last_name"
              className="block text-sm font-semibold text-gray-800"
            >
              Last Name
            </label>
            <input
              onChange={handleChange}
              id="last_name"
              value={formData.last_name}
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <input
              onChange={handleChange}
              id="email"
              value={formData.email}
              type="email"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="phone_number"
              className="block text-sm font-semibold text-gray-800"
            >
              Phone Number
            </label>
            <input
              onChange={handleChange}
              id="phone_number"
              value={formData.phone_number}
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="institution"
              className="block text-sm font-semibold text-gray-800"
            >
              Institution
            </label>
            <input
              onChange={handleChange}
              id="institution"
              value={formData.institution}
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="specialization"
              className="block text-sm font-semibold text-gray-800"
            >
              Specialization
            </label>
            <input
              onChange={handleChange}
              value={formData.specialization}
              id="specialization"
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="academic_rank"
              className="block text-sm font-semibold text-gray-800"
            >
              Academic Rank
            </label>
            <input
              onChange={handleChange}
              id="academic_rank"
              value={formData.academic_rank}
              type="text"
              className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mt-8 mb-2">
            <label
              htmlFor="courseFile"
              className="block text-sm font-semibold text-gray-800"
            >
              Upload your Resume/CV here:
            </label>
            <input
              type="file"
              id="resume"
              onChange={handleFileUpload}
              className="block w-full rounded-md border mt-2 file:bg-gradient-to-r file:from-red-500 file:to-yellow-500 file:text-white file:h-full file:px-4 file:py-1 file:border-none focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mt-12">
            <button
              onClick={handleSubmit}
              className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TeacherRegistrationForm;
