import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { useState } from "react";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import CourseUpload from "./pages/CourseUpload";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BookListing from "./pages/BookListing";
import BookDetail from "./pages/BookDetial";
import CreateAnnouncement from "./pages/CreateAnnouncement";
import CreateEvent from "./pages/CreateEvent";
import StudentSignup from "./pages/StudentSignup";
import AddCourse from "./pages/AddCourse";
import CreateCourseRegistration from "./pages/CreateCourseRegistration";
import StudentRegistration from "./pages/StudentRegistration";
import StudentMainPage from "./pages/StudentMainPage";
import Profile from "./pages/Profile";
import TeacherMainpage from "./pages/TeacherMainpage";
import AdminMainPage from "./pages/AdminMainPage";
import StudentMarkList from "./pages/StudentMarkList";
import ViewGrade from "./pages/ViewGrade";
import AssignTeacherToCourse from "./pages/AssignTeacherToCourse";
import StudentsList from "./pages/StudentsList";
import TeachersList from "./pages/TeachersList";
import GetAssignedCourses from "./pages/GetAssignedCourses";
import StudentSignupFromFile from "./pages/StudentSignupFromFile";
import LandingPage from "./pages/LandingPage";
import HardCopyBooks from "./pages/HardCopyBooks";
import TeacherRegistrationForm from "./pages/TeacherRegistrationForm";
import TeachersApplicationList from "./pages/TeachersApplicationList";
import LibraryBooks from "./pages/LibraryBooks";



function App() {

  const [ selectedPage, setSelectedPage] = useState('home')

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home selectedPage={selectedPage} setSelectedPage={setSelectedPage}/>}/>
          
          <Route path="login" element={ <Login/>}/>
          <Route path="apply/teacher" element={ <TeacherRegistrationForm/>}/>
          
          <Route path="admin" element={ <AdminMainPage/>} >
            <Route path="" element={ <LandingPage/>}/>
            <Route path="profile" element={ <Profile/>}/>
            <Route path="signup/teacher" element={ <Signup/>}/>
            <Route path="signup/student" element={ <StudentSignup/>}/>
            <Route path="signup/student/file" element={ <StudentSignupFromFile/>}/>
            <Route path="student/list" element={ <StudentsList/>}/>
            <Route path="teacher/list" element={ <TeachersList/>}/>
            <Route path="teachers/applied" element={ <TeachersApplicationList/>}/>
            <Route path="create/course" element={ <AddCourse/>}/>
            <Route path="create/course-registration" element={ <CreateCourseRegistration/>}/>
            <Route path="create/announcement" element={ <CreateAnnouncement/>}/>
            <Route path="create/event" element={ <CreateEvent />}/>
            <Route path="add/books" element={ <HardCopyBooks />}/>
            <Route path="assign" element={ <AssignTeacherToCourse/>}/>
          </Route>
          
          <Route path="student" element={ <StudentMainPage/>}> 
            <Route path="" element={ <LandingPage/>}/>
            <Route path="profile" element={ <Profile/>}/>
            <Route path="register" element={ <StudentRegistration/>}/>
            <Route path="books/listings" element={ <BookListing/>}/>
            <Route path="books/detail/:id" element={ <BookDetail/>}/>
            <Route path="library" element={ <LibraryBooks/>}/>
            <Route path="grade" element={ <ViewGrade/>}/>
          </Route>
          
          <Route path="teacher" element={ <TeacherMainpage/>}> 
            <Route path="" element={ <LandingPage/>}/>
            <Route path="profile" element={ <Profile/>}/>
            <Route path="books/listings" element={ <BookListing/>}/>
            <Route path="books/detail/:id" element={ <BookDetail/>}/>
            <Route path="book/upload" element={ <CourseUpload/>}/>
            <Route path="library" element={ <LibraryBooks/>}/>
            <Route path="courses" element={ <GetAssignedCourses/>}/>
            <Route path="courses/list/:course_id/:section" element={ <StudentMarkList/>}/>
          </Route>
          
        </Routes>  
      </BrowserRouter>

      
      
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>


  );
}

export default App;
