import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Loader from '../components/Loader'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import getApiKey from '../apiKeys'

function Signup() {
    const endpoint = getApiKey()
  const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        institution: "",
        specialization: "",
        academic_rank: "",
        password: "",
    })
    const token = localStorage.getItem('token')

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        console.log(formData)
        try {
            const response = await Axios.post(`${endpoint}/api/teacher-registration`, formData, { headers: {"Authorization" : `Bearer ${token}`} })
            console.log(response.data)
            toast.success("Created Account succefully")
            navigate('/admin/teacher/list')
            
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message)
        }
        
        setLoading(false)
    }

  return loading ? <Loader /> : (
    <div className="flex flex-col justify-center my-10 mt-40">
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:max-w-xl">
            <h1 className="text-3xl font-semibold text-center text-red-500 uppercase">
                Teacher Sign Up
            </h1>
            <form className="mt-6">
                <div className="mb-2">
                    <label
                        htmlFor="first_name"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        First Name
                    </label>
                    <input
                        onChange={handleChange}
                        id='first_name'
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="middle_name"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Middle Name
                    </label>
                    <input
                        onChange={handleChange}
                        id='middle_name'
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="last_name"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Last Name
                    </label>
                    <input
                        onChange={handleChange}
                        id='last_name'
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="email"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Email
                    </label>
                    <input
                        onChange={handleChange}
                        id='email'
                        type="email"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="phone_number"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Phone Number
                    </label>
                    <input
                        onChange={handleChange}
                        id='phone_number'
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="institution"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Institution
                    </label>
                    <input
                        onChange={handleChange}
                        id='institution'
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="specialization"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Specialization
                    </label>
                    <input
                        onChange={handleChange}
                        id='specialization'
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="academic_rank"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Academic Rank
                    </label>
                    <input
                        onChange={handleChange}
                        id='academic_rank'
                        type="text"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mb-2">
                    <label
                        htmlFor="password"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Password
                    </label>
                    <input
                        onChange={handleChange}
                        id='password'
                        type="password"
                        className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-red-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div className="mt-12">
                    <button
                        onClick={handleSubmit} 
                        className="w-full px-4 py-2 tracking-wide text-white bg-gradient-to-r from-red-500 to-yellow-500 rounded-md transform transition-all hover:scale-105 duration-500 focus:outline-none">
                        Signup
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Signup