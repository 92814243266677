import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
import Announcements from "../pages/Announcements";
import Close from '../assets/close.svg'

const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 100,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };
  

const Modal = ({ handleClose }) => {

    return (
      <Backdrop onClick={handleClose}>
          <motion.div
            onClick={(e) => e.stopPropagation()}  
            className="w-full md:w-2/3 bg-white p-6 md:px-16 md:py-10 rounded-xl shadow-lg"
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Announcements />
            <div onClick={handleClose} className="absolute top-3 right-3 border border-slate-500 hover:bg-yellow-500 hover:scale-110 hover:border-none duration-300 hover:cursor-pointer w-12 h-12 rounded-full flex justify-center items-center">
                <img src={Close} alt="close" className="w-6 h-6"/>
            </div>
          </motion.div>
      </Backdrop>
    );
  };

  
  export default Modal;