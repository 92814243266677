import React, { useEffect, useMemo, useState } from "react";
import ListingItem from "../components/ListingItem";
import axios from "axios";
import Loader from "../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import getApiKey from "../apiKeys";
import FilterComponent from "../components/FilterComponent";
import DataTable from "react-data-table-component";

function BookListing() {
  const endpoint = getApiKey();

  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const decoded = jwt_decode(token);
  const navigate = useNavigate();
  const role = decoded.role.toLowerCase();

  const columns = [
    {
      name: "Book Title",
      selector: (book) => book.title,
      sortable: true,
    },
    {
      name: "Course Title",
      selector: (book) => book.course_title,
      sortable: true,
    },
    {
      name: "Course Program",
      selector: (book) => book.program, //change starts here
      sortable: true,
    },
    {
      name: "Book Detail",
      selector: (book) => (
        <Link
          to={`/${role}/books/detail/${book.id}`}
          className="text-blue-500 hover:underline"
        >
          View Detail
        </Link>
      ),
      // sortable: true,
    },
    {
      name: "Download",
      selector: (book) => (
        <a
          href={`${endpoint}${book.file}`}
          className="text-blue-500 hover:underline"
        >
          Download
        </a>
      ),
      // sortable: true,
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex justify-end items-center">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        {decoded && decoded.role === "Teacher" && (
          <Link to="/teacher/book/upload">
            <div className="bg-gradient-to-r from-red-500 to-yellow-500 text-white px-6 py-2 hover:cursor-pointer hover:scale-105 rounded-full transition-all">
              Upload a book
            </div>
          </Link>
        )}
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    setLoading(true);
    const fetchBooks = async () => {
      const response = await axios.get(`${endpoint}/api/books`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBooks(response.data);
      console.log(books);
      setLoading(false);
    };

    fetchBooks();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="relative flex flex-col justify-center my-10 mt-40">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4">
        <DataTable
          title="E-Books"
          columns={columns}
          data={books.filter((item) => {
            if (filterText === "") {
              return item;
            } else if (
              item.title.toLowerCase().includes(filterText.toLowerCase()) ||
              item.course_title
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              item.program.toLowerCase().includes(filterText.toLowerCase())
            ) {
              return item;
            }
          })}
          progressPending={loading}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
        />
      </div>
    </div>
  );
}

export default BookListing;
