import React from 'react'
import { useNavigate } from 'react-router-dom'

function ListingItem({course, role}) {
    const navigate = useNavigate()
  return (
    <div className="bg-white shadow-xl shadow-gray-100 w-full max-w-4xl flex flex-col items-start md:flex-row gap-3 md:items-center hover:cursor-pointer justify-between px-5 py-4 rounded-md" onClick={() => navigate(`/${role}/books/detail/${course.id}`)}>
        <div>
            <span className="text-red-500 text-sm">{course.course_title}</span>
            <h3 className="font-bold mt-px">{course.title}</h3>
            <div className="flex items-center gap-3 mt-2">
                <span className="bg-gradient-to-r from-red-500 to-yellow-500 text-white rounded-full px-3 py-1 text-sm">{course.program}</span>
            </div>
        </div>
        <div className='self-end md:self-center'>
            <button><p className="bg-gradient-to-r from-red-500 to-yellow-500 text-white px-4 py-2 rounded-md flex items-center">Download</p></button>
        </div>
   </div>
  )
}

export default ListingItem