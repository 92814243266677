import React, { useEffect, useMemo, useState } from "react";
import pfp from "../assets/Default_pfp.svg";
import deleteSvg from "../assets/delete.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import FilterComponent from "../components/FilterComponent";
import DataTable from "react-data-table-component";
import getApiKey from "../apiKeys";
import { toast } from "react-toastify";

function TeachersApplicationList() {
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const endpoint = getApiKey();

  const columns = [
    {
      name: "Full Name",
      selector: (teacher) =>
        teacher.first_name +
        " " +
        teacher.middle_name +
        " " +
        teacher.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (teacher) => teacher.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (teacher) => teacher.phone_number, //change starts here
      sortable: true,
    },
    {
      name: "Institution",
      selector: (teacher) => teacher.institution,
      sortable: true,
    },
    {
      name: "Specialization",
      selector: (teacher) => teacher.specialization,
      sortable: true,
    },
    {
      name: "Academic Rank",
      selector: (teacher) => teacher.academic_rank,
      sortable: true,
    },
    {
      name: "Resume/CV",
      selector: (teacher) => (
        <a
          href={`${endpoint}${teacher.resume}`}
          className="text-blue-500 hover:underline"
        >
          Download
        </a>
      ),
      sortable: true,
    },
    {
      name: "Delete",
      selector: (teacher) => (
        <button
          onClick={() => deleteApplication(teacher)}
        >
            <img src={deleteSvg} alt='delete' className='w-6 h-6 hover:scale-110  hover:cursor-pointer duration-300'/>
        </button>
      ),
      sortable: true,
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    const fetchTeachers = async () => {
      const response = await axios.get(`${endpoint}/api/careers`, {
        headers: { Authorization: `Bearer ${token}` },
      }); 
      setTeachers(response.data);
      console.log("Teachers", response.data);
      setLoading(false);
    };

    setLoading(true);
    fetchTeachers();
  }, []);

  const deleteApplication = async (teacher) => {
    setLoading(true)
    try {
        await axios.delete(`${endpoint}/api/careers/${teacher.id}`, { headers: {"Authorization" : `Bearer ${token}`} })
        setTeachers((prev) => {
            const newList = prev.filter((t) => t.id !== teacher.id)
            return newList
        })
        toast.success('Deleted Sucessfully')
        setLoading(false)
    } catch (error) {
        toast.error(error.response.data.error)
        setLoading(false)
    }
  }
  return loading ? (
    <Loader />
  ) : (
    <div className="relative flex flex-col justify-center my-10 mt-40">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl lg:w-3/4">
        <DataTable
          title="Teacher Applications"
          columns={columns}
          data={teachers.filter((item) => {
            if (filterText === "") {
              return item;
            } else if (
              item.first_name
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              item.middle_name
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              item.last_name.toLowerCase().includes(filterText.toLowerCase())
            ) {
              return item;
            }
          })}
          progressPending={loading}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
        />
      </div>
    </div>
  );
}

export default TeachersApplicationList;
